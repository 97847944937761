<template>
  <v-bottom-sheet v-model="showProfileSheet" persistent fullscreen scrollable class="profile-sheet-main">
    <v-sheet class="profile-sheet">
      <div class="profile-sheet-wrapper">
        <div class="profile-sheet-header">
          <!-- <v-img src="/static/image/profile/member-header-bg.png" :contain="true" class="profile-sheet-header-image"></v-img> -->
          <div class="profile-sheet-header-wrapper">
            <div class="profile-sheet-close-wrapper" @click="closeProfileSheet">
              <v-icon class="profile-sheet-close-icon white--text">clear</v-icon>
            </div>
            <div class="header-member-name">
              <v-avatar size="84" class="profile-sheet-profile-avatar">
                <div>{{ memberInfo.name ? memberInfo.name.slice(0, 1).toUpperCase() : memberInfo.memberCode.slice(0, 1).toUpperCase() }}</div>
                <!-- <v-img src="/static/image/profile/member-header-bg.png" :contain="true" class="profile-sheet-header-image"></v-img> -->
              </v-avatar>
              <div>{{ memberInfo.name || memberInfo.memberCode }}</div>
            </div>
          </div>
        </div>
        <div class="profile-sheet-body">
          <div class="profile-sheet-body-wrapper">
            <div class="profile-sheet-item profile-sheet-wallet">
              <div class="profile-sheet-balance">
                <label>{{ $t(`label.mainWallet`) }}</label>
                <div @click="getMemberWallet()" class="refresh-balance-icon"></div>
                <div @click="maskBalance()">
                  <v-icon v-if="showBalance" class="mask-balance">mdi-eye-off</v-icon>
                  <v-icon v-else class="mask-balance">remove_red_eye</v-icon>
                </div>
              </div>
              <div>
                <label class="text-capitalize balance" v-if="notLoading">
                  <span v-if="showBalance">{{ memberWallet.totalBalance | currency(currentCurrency) | maskNumber }}</span>
                  <span v-else>{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
                </label>
                <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
              </div>
            </div>
            <div class="profile-sheet-item">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.fund`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <div class="profile-sheet-item-column" @click="routeTo('DEPOSIT')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-deposit.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.deposit`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('WITHDRAWAL')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-withdrawal.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.withdrawal`) }}</label>
                </div>
              </div>
            </div>
            <div class="profile-sheet-item">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.history`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <div class="profile-sheet-item-column" @click="routeTo('BETTING_RECORD')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-total.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.bettingRecors`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('TURNOVER_RECORD')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-total.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.turnoverRecors`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('TRANSFER_RECORD')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-tranfer.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.transferRecors`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('BONUS')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-bonus.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.bonus`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('TRANSACTION')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-transaction.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.transactionRecors`) }}</label>
                </div>
              </div>
            </div>
            <div class="profile-sheet-item">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.event`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <!-- <div class="profile-sheet-item-column" @click="routeTo('DAILY_CHECK_IN')">

                </div> -->

                <div class="profile-sheet-item-column" @click="routeTo('CLAIM_VOUCHER')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-grabcoin.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.claimVoucher`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('REWARDS')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-checkin.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.rewards`) }}</label>
                </div>
                <!-- <div class="profile-sheet-item-column" @click="routeTo('DAILY_CHECK_IN')">

                </div> -->
              </div>
            </div>
            <div class="profile-sheet-item">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.profile`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <div class="profile-sheet-item-column" @click="routeTo('PERSONAL')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-info.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.personalInfo`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('CHANGE_PASSWORD')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-reset.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.changePassword`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('BANK_DETAIL')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-info.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.bankDetail`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('INBOX')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-inbox.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.inbox`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('REFERRAL_SUMMARY')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-referral.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.referral`) }}</label>
                </div>
                <div class="profile-sheet-item-column" v-if="affiliateBonus.status == 1" @click="routeTo('REFERRAL_BONUS')">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-referral.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.referralBonus`) }}</label>
                </div>
              </div>
            </div>
            <div class="profile-sheet-item">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.contactUs`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <div class="profile-sheet-item-column" @click="routeTo('LIVECHAT', true)">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-support.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.support`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('WHATSAPP', true)">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-whatsapp.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.whatsApp`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('TELEGRAM', true)">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-telegram.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.telegram`) }}</label>
                </div>
                <div class="profile-sheet-item-column" @click="routeTo('FACEBOOK', true)">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-fb.svg"></v-img>
                  </div>
                  <label>{{ $t(`label.facebook`) }}</label>
                </div>
              </div>
            </div>
            <div class="profile-sheet-item" v-if="isAndroid || isDesktop">
              <div class="profile-sheet-item-header">
                <div class="profile-sheet-item-title">{{ $t(`label.download`) }}</div>
              </div>
              <div class="profile-sheet-item-divider"></div>
              <div :class="$vuetify.breakpoint.mdAndDown ? 'profile-sheet-item-row-mobile' : 'profile-sheet-item-row'">
                <div class="profile-sheet-item-column" @click="downloadAPK">
                  <div class="profile-sheet-icon">
                    <v-img src="/static/image/profile/icon-downloadapk.png"></v-img>
                  </div>
                  <label>{{ $t(`label.downloadandroid`) }}</label>
                </div>
              </div>
            </div>
            <div class="logout-container">
              <div class="logout-btn" @click="logoutBtn">
                <div class="logout-icon"></div>
                <div>{{ $t(`label.logout`) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { MEMBER_DETAIL, MEMBER_WALLET } from '@/store/member.module'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import { AFFILIATE_ONE_TIME_BONUS_DETAIL } from '@/store/affiliate.bonus.module'

export default {
  name: 'ProfileSheet',
  props: {
    showProfileSheet: {
      type: Boolean,
      required: true,
      default: false
    },
    closeProfileSheet: {
      type: Function,
      required: false
    },
    logout: {
      type: Function,
      required: false
    }
  },
  data: () => ({
    routeName: ROUTE_NAME,
    notLoading: true,
    currentCurrency: uiHelper.getCurrency(),
    showBalance: true,
    isAndroid: uiHelper.isAndroid(),
    isDesktop: uiHelper.getPlatform() == 'desktop'
  }),
  created() {
    if (this.isLoggedIn) {
      this.getMemberDetail()
      // this.getMemberWallet()
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    affiliateBonus() {
      return this.$store.state.affiliateBonus.affiliateBonusDetail
    }
  },
  methods: {
    getAffiliateBonusDetail() {
      this.$store.dispatch(`${AFFILIATE_ONE_TIME_BONUS_DETAIL}`)
    },
    maskBalance() {
      this.showBalance = !this.showBalance
    },
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getMemberWallet() {
      if (!this.lastDispatchTime || Date.now() - this.lastDispatchTime > 15 * 1000) {
        this.notLoading = false
        this.lastDispatchTime = Date.now() // Update last dispatch time
        this.$store.dispatch(MEMBER_WALLET)
      }
      this.maskBalance()
    },
    logoutBtn() {
      this.logout()
      this.closeProfileSheet()
    },
    routeTo(routeTo, extLink) {
      let params
      this.closeProfileSheet()
      switch (routeTo) {
        case 'DEPOSIT':
          // routeTo = ROUTE_NAME.DEPOSIT
          routeTo = ROUTE_NAME.FUNDS
          params = {
            tab: 'deposit'
          }
          break
        case 'WITHDRAWAL':
          routeTo = ROUTE_NAME.FUNDS
          params = {
            tab: 'withdrawal'
          }
          break
        case 'TRANSFER_RECORD':
          routeTo = ROUTE_NAME.TRANSFER_HISTORY
          params = {
            tab: 'TRANSFER'
          }
          break
        case 'BETTING_RECORD':
          routeTo = ROUTE_NAME.BETTING_HISTORY
          params = {
            tab: 'TRANSFER'
          }
          break
        case 'TURNOVER_RECORD':
          routeTo = ROUTE_NAME.TURNOVER_RECORD
          params = {
            tab: 'RUNNING'
          }
          break
        case 'BONUS':
          routeTo = ROUTE_NAME.BONUS_HISTORY
          params = {
            tab: 'REBATE'
          }
          break
        case 'TRANSACTION':
          routeTo = ROUTE_NAME.TRANSACTION_HISTORY
          params = {
            tab: 'DEPOSIT'
          }
          break
        case 'PERSONAL':
          routeTo = ROUTE_NAME.PERSONAL
          break
        case 'BANK_DETAIL':
          routeTo = ROUTE_NAME.BANK_ACCOUNT
          break
        case 'CHANGE_PASSWORD':
          routeTo = ROUTE_NAME.CHANGE_PASSWORD
          break
        case 'INBOX':
          routeTo = ROUTE_NAME.INBOX
          break
        case 'REFERRAL_SUMMARY':
          routeTo = ROUTE_NAME.REFERRAL_SUMMARY
          break
        case 'REFERRAL_BONUS':
          routeTo = ROUTE_NAME.REFERRAL_BONUS
          break
        case 'REWARDS':
          routeTo = ROUTE_NAME.REWARDS
          break
        case 'CLAIM_VOUCHER':
          routeTo = ROUTE_NAME.CLAIM_VOUCHER
          break
      }

      if (extLink == undefined || !extLink) {
        this.$router.push({
          name: routeTo,
          params
        })
      } else {
        /*external link */
        if (routeTo == 'FACEBOOK') {
          window.open('https://www.facebook.com/jaya9official')
        } else if (routeTo == 'WHATSAPP') {
          window.open('https://wa.me/601120925637')
        } else if (routeTo == 'TELEGRAM') {
          window.open('https://t.me/Jaya9Officials')
        } else if (routeTo == 'LIVECHAT') {
          window.open(' https://direct.lc.chat/14469159/')
        }
      }
    },
    downloadAPK() {
      uiHelper.downloadAPK()
    }
  },
  watch: {
    memberWallet() {
      this.notLoading = true
    },
    isLoggedIn() {
      this.getAffiliateBonusDetail()
    }
  }
}
</script>

<style lang="scss">
.profile-sheet {
  $top: -35vw;

  position: relative;
  background-color: var(--v-base-base) !important;
  overflow-y: auto;
  overflow-x: hidden;

  .profile-sheet-wrapper {
    max-width: 959px;
    margin: auto;

    .profile-sheet-header {
      position: relative;
      height: 45.3333333333vw;
      background-position-y: -30vw;
      background: url('/static/image/profile/member-header-bg.png');
      background-size: contain;
      background-attachment: fixed;
      background-repeat: no-repeat;

      .profile-sheet-header-image {
        top: 0;
      }

      .profile-sheet-header-wrapper {
        max-width: 1240px;
        margin: auto;

        .profile-sheet-close-wrapper {
          position: absolute;
          top: -32px;
          right: -32px;
          padding: 42px;
          border-radius: 50%;
          background-color: var(--v-base-base);
          cursor: pointer;

          .profile-sheet-close-icon {
            position: absolute;
            bottom: 20px;
            left: 20px;
          }
        }

        .header-member-name {
          position: absolute;
          top: 32px;
          left: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          .profile-sheet-profile-avatar {
            color: var(--v-secondary-base);
            font-size: 44px;
            background-color: black;
            box-shadow: 2px 2px 1px 3px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .profile-sheet-body {
      position: relative;
      top: $top;
      left: 0;
      width: 100%;
      background: var(--v-base-base);

      .profile-sheet-body-wrapper {
        max-width: 1240px;
        margin: auto;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .profile-sheet-item {
          background-color: var(--v-base-lighten1);

          .profile-sheet-item-header {
            padding: 8px;

            .profile-sheet-item-title {
              position: relative;
              font-size: 14px;
              padding-left: 12px;

              &::before {
                content: '';
                position: absolute;
                top: 10%;
                left: 0;
                width: 4px;
                height: 80%;
                background-color: var(--v-primary-base);
              }
            }
          }

          .profile-sheet-item-divider {
            width: 100%;
            height: 1px;
            background-color: var(--v-base-base);
            margin: 2px 0px;
          }

          .profile-sheet-item-row-mobile {
            display: flex;
            align-items: center;
            padding: 0px;
            flex-wrap: wrap;

            .profile-sheet-item-column {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              align-self: baseline;
              gap: 3px;
              width: 19%;
              cursor: pointer;
              margin: 10px;

              .profile-sheet-icon {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.3);
              }

              label {
                font-size: 11px;
                text-align: center;
              }
            }
          }

          .profile-sheet-item-row {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0px;
            flex-wrap: wrap;

            .profile-sheet-item-column {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              align-self: baseline;
              gap: 5px;
              width: 11%;
              cursor: pointer;
              margin: 10px;

              .profile-sheet-icon {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.3);
              }

              label {
                font-size: 12px;
                text-align: center;
              }
            }
          }

          .disbaledDiv {
            pointer-events: none;
            opacity: 0.4;
          }
        }

        .profile-sheet-wallet {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 16px;
          font-size: 12px;

          .profile-sheet-balance {
            display: flex;
            gap: 4px;

            label {
              color: var(--v-secondary-lighten2);
              align-self: center;
            }

            .mask-balance {
              color: var(--v-secondary-lighten2);
              cursor: pointer;
            }

            .refresh-balance-icon {
              width: 24px;
              height: 24px;
              background-color: var(--v-secondary-lighten2);
              mask: url(/static/image/profile/icon-refresh-type01.svg);
              mask-repeat: no-repeat;
              mask-position: center;
              -webkit-mask: url(/static/image/profile/icon-refresh-type01.svg);
              -webkit-mask-repeat: no-repeat;
              -webkit-mask-position: center;
              cursor: pointer;
            }
          }

          .balance {
            color: #aaa;
            font-size: 18px;
          }
        }
      }
    }

    .logout-container {
      background-color: var(--v-base-lighten1);
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logout-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        .logout-icon {
          width: 28px;
          height: 28px;
          background-color: var(--v-text_color-base);
          mask: url(/static/image/profile/icon-logout.svg);
          mask-repeat: no-repeat;
          mask-position: center;
          -webkit-mask: url(/static/image/profile/icon-logout.svg);
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-position: center;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 959px) {
  $top: -25vw;

  .profile-sheet-header {
    background-position-y: $top !important;
  }

  .profile-sheet-body {
    top: $top !important;
  }
}

@media (max-width: 599px) {
  $top: -10vw;

  .profile-sheet-header {
    background-position-y: $top !important;
  }

  .profile-sheet-body {
    top: $top !important;
  }

  .profile-sheet-wallet {
    padding: 16px 4px !important;
  }
}
</style>
