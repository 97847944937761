import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

export const announcementService = {
  getAnnouncementListing,
  getAnnouncement,
  getPopupListing
}

//API URLs
const ANNOUNCEMENT = {
  ANNOUNCEMENT: '/announcement'
}

function getPopupListing(filter){
  const requestOptions = {
    params: {
      site: filter.site,
      type: filter.type,
      size: filter.itemsPerPage,
      page: filter.page,
      currency: SHARED.DEFAULT_CURRENCY
    }
  }
  return apiHelper().get(`${ANNOUNCEMENT.ANNOUNCEMENT}`, requestOptions)
}

function getAnnouncementListing(filter) {
  const requestOptions = {
    params: {
      site: filter.site,
      type: filter.type,
      size: filter.itemsPerPage,
      page: filter.page,
      currency: SHARED.DEFAULT_CURRENCY
    }
  }
  return apiHelper().get(`${ANNOUNCEMENT.ANNOUNCEMENT}`, requestOptions)
}

function getAnnouncement(processedData, id) {
  const requestOptions = {
    params: {
      site: processedData.site,
      type: processedData.type,
      currency: SHARED.DEFAULT_CURRENCY
    }
  }
  return apiHelper().get(`${ANNOUNCEMENT.ANNOUNCEMENT}/${id}`, requestOptions)
}
