<template>
<button class="button-primary"
    :disabled="disabled"
    :class="customClass"
    @click="action">{{ title }}
</button>
</template>

<script>
export default {
    name: 'buttonPrimary',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        action: {
            type: Function,
            required: true,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        customClass: {
            type: String,
            required: false,
            default: ''
        },
    }
}
</script>

<style lang="scss">
.button-primary {
    position: relative;
    min-height: 28px;
    min-width: 80px;
    border-radius: 3px;
    background: linear-gradient(to right, #fbc103 0%, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
    background-position: -50% 0;
    box-shadow: 0 6px 6px 1px rgb(6 8 15 / 10%),
        0 6px 6px 1px rgb(6 8 15 / 10%),
        0 6px 6px 1px rgb(6 8 15 / 10%),
        inset 0 0 3px 0 #ffdd8f;
    transition:
        background-position .3s ease-in,
        box-shadow .5s ease-out .1s,
        text-shadow .3s ease-in .1s;
    color: #ffffff;
}

.button-primary::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('/static/btn-light.png') no-repeat center top;
    background-position: 0 0;
    background-size: 100%;
}

.button-primary:hover {
    transition-duration: 0.6s;
    transition-delay: 0.3s;
    background-position: 0 0;
    background: linear-gradient(to right, #fbc103 0%, #f79604 15%, #f79604 65%, #fbc103 100%);
    box-shadow: 0 2px 2px 1px rgb(6 8 15 / 10%), 0 4px 4px 1px rgb(6 8 15 / 10%), 0 8px 8px 1px rgb(6 8 15 / 10%), 0 16px 16px 1px rgb(6 8 15 / 10%), 0 32px 32px 1px rgb(6 8 15 / 10%), inset 0 0 3px 0 #ffdd8f;
    text-shadow: 0 1px 3px #967302;
}
</style>
