<template>
<v-col no-gutters class="language_panel">
    <v-row no-gutters justify="space-between" align-content="center" class="d-flex flex-nowrap" v-for="(lang, index) in filterLanguages" :key="lang.currency">
        <div no-gutters class="align-self-center flex-shrink-1">
            <v-avatar class="language-button" :size="$vuetify.breakpoint.xsOnly ? '35': '48'">
                <img :src="`/static/image/country/${lang.img}.svg`" alt="JAYA9 cricket exchange" />
            </v-avatar>
        </div>
        <div class="align-self-center flex-shrink-1 mx-2">
            <label class="">{{lang.currencyCd}} {{lang.currency}}</label>
        </div>
        <v-row no-gutters justify="center" class="align-self-center flex-grow-1">
            <!-- <v-col v-model="selectedLanguage" class="pl-2" v-for="l in lang.language" :key="l.cd">
                <v-btn :value="lang.currency + l.cd" class="font-weight-bold text-capitalize flex-grow-1" outlined @click="changeLanguage(l.cd, lang.currency)">{{l.title}}</v-btn>
            </v-col> -->

            <v-btn-toggle v-model="selectedLanguage" :group="true" :borderless="true" dense class="pl-2 flex-grow-1" v-for="l in lang.language" :key="l.cd">
                <v-btn :value="lang.currency + l.cd" class="font-weight-bold text-capitalize full-width" outlined @click="changeLanguage(l.cd, lang.currency)">{{l.title}}</v-btn>
            </v-btn-toggle>
        </v-row>
    </v-row>
    <v-row no-gutters class="mt-2">
        <v-divider v-if="index < languageList.length -1 " class="language-divider"></v-divider>
    </v-row>
</v-col>
</template>

<script>
import stingFormat from 'string-format'
import {
    formValidator,
    uiHelper,
    errorCodeHelper
} from '@/util'
import {
    SHARED
} from '@/constants/constants'
import {
    MEMBER_LOGIN
} from '@/store/member.module'
export default {
    name: 'appLanguage',
    props: {
        closeAction: {
            type: Function,
            required: true,
            default: () => {}
        }
    },
    data: () => ({
        languageList: [{
                currency: 'BDT',
                currencyCd: '৳',
                img: "BDT",
                language: [{
                        title: 'English',
                        cd: 'en'
                    },
                    {
                        title: 'Bengali',
                        cd: 'bd'
                    }
                ]
            }
            // {
            //     currency: 'INR',
            //     currencyCd: '₹',
            //     img: "INR",
            //     language: [
            //         {
            //             title: 'English',
            //             cd: 'en'
            //         },
            //         {
            //             title: 'Tamil',
            //             cd: 'ta'
            //         }
            //     ]
            // }
        ],
        selectedLanguage: '',
        currentLanguage: uiHelper.getLanguage()
    }),
    computed: {
        isLoggedIn() {
            return this.$store.state.member.isLoggedIn
        },
        currentCurrency() {
            return uiHelper.getCurrency()
        },
        filterLanguages() {
            if (this.isLoggedIn) {
                let list = this.languageList.filter(l => l.currency == this.currentCurrency)
                if (list && list.length > 0)
                    return list
                else
                    return this.languageList
            } else {
                return this.languageList
            }
        }
    },
    created() {
        this.selectedLanguage = this.currentCurrency + this.currentLanguage
    },
    methods: {
        changeLanguage(languageCd, currCd) {
            this.$store.dispatch(`${SHARED.LOADING}`)
            uiHelper.removeCookie('language')
            uiHelper.setCookie('language', languageCd)
            uiHelper.removeCookie('changeLanguage')
            uiHelper.setCookie('changeLanguage', true)
            uiHelper.setCurrency(currCd)
            window.location.reload()
        }
    }
}
</script>

<style lang="scss">
.language_panel {
    color: #000000;
    background-color: #ffffff;
    padding: 12px 16px !important;

    .language-divider {
        border-color: var(--v-border-base);
    }

    .v-btn-toggle--group {
        .v-btn {
            color: #000000;
            border: 1px solid var(--v-description-base);

            &.v-btn--active {
                color: var(--v-primary-base);
                border: 1px solid var(--v-primary-base);
            }
        }

        .v-btn:hover {
            color: var(--v-primary-base);
            border: 1px solid var(--v-primary-base);
        }
    }

    .v-btn::before {
        background-color: unset;
    }
}

@media (max-width: 959px) {
    // .language_panel {
    //     color: unset;
    //     background-color: #3e3e3e;
    //     padding: unset;

    //     .v-btn-toggle--group {
    //         .v-btn {
    //             color: unset;
    //             border: 1px solid #ffffff;

    //             &.v-btn--active {
    //                 color: var(--v-secondary-base);
    //                 border-color: var(--v-secondary-base);
    //                 border: 1px solid var(--v-secondary-base);
    //             }
    //         }
    //     }
    // }
}

@media (max-width: 425px) {
    .language_panel {
        .v-btn {
            font-size: 12px !important;
            height: 35px !important;
        }
    }
}
</style>
