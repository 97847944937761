<template>
  <v-container class="fill-height pt-0">
    <v-row no-gutters :class="'px-3'">
      <v-col cols="12">
        <v-card-text v-if="dialogMessageTitle" class="text-capitalize pa-0 pb-2 font-weight-bold title text-center" style="white-space: normal">{{ dialogMessageTitle }}</v-card-text>
        <v-card-text class="pa-0 pb-2 mt-0 text-none text-center" v-for="message in dialogMessage" :key="message">{{ message }}</v-card-text>
        <v-row>
          <v-col cols="6" v-for="(button, index) in dialogButton" :key="index" class="mx-auto d-flex justify-center">
            <app-button class="dialog-button" :key="button.title" :title="button.title" :customClass="button.class" :action="button.action"></app-button>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { locale } from '@/util'
export default {
  name: 'AppPageDialog',
  props: {
    dialogMessageTitle: {
      type: String,
      required: false,
      default: locale.getMessage(`label.system`)
    },
    dialogMessage: {
      type: Array,
      required: false,
      default: () => []
    },
    dialogButton: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
<style lang="scss">
.text-none {
  text-transform: none;
}
</style>
