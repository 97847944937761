<template>
    <v-dialog v-model="display" :width="330">
        <template v-slot:activator="{ on }">
            <v-text-field class="input-field" outlined :label="label" :hint="hint" :persistent-hint="hint != ''"
                          :value="value" v-on="on" readonly :rules="rules"></v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-tabs fixed-tabs v-model="activeTab" color="primary" class="tabs_calendar">
                    <v-tab key="calendar">
                        <slot name="dateIcon">
                            <v-icon>event</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab key="timer" :disabled="dateSelected" v-if="type == 'datetime'" @click="showTimePicker">
                        <slot name="timeIcon">
                            <v-icon>access_time</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker v-model="date" @input="showTimePicker" full-width color="primary" :max="maxDate"
                                       :locale="language"></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker ref="timer" class="v-time-picker-custom" v-model="time" use-seconds ampm-in-title
                                       :max="maxTime" color="primary" style="width:100%"
                                       :locale="language"></v-time-picker>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="actions" :parent="this">
                    <v-btn color="primary" text @click="okHandler">{{ $t('label.confirm') }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {format} from 'date-fns'
    import moment from 'moment'
    import {DATE} from '@/constants/constants'
    import {uiHelper} from '@/util'

    const DEFAULT_DATE = new Date()
    const DEFAULT_START_TIME = '00:00:00'
    const DEFAULT_END_TIME = '23:59:59'
    const DEFAULT_CLEAR_TEXT = 'CLEAR'

    export default {
        name: 'appDateTimePicker',
        model: {
            prop: 'value'
        },
        props: {
            hint: {
                type: String,
                required: false,
                default: ''
            },
            maxDate: {
                type: String,
                default: undefined
            },
            placeholder: {
                type: String,
                default: ''
            },
            value: {
                required: true
            },
            label: {
                type: String,
                required: true,
                default: ''
            },
            input: {
                type: Boolean,
                required: false,
                default: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            hideDetail: {
                type: Boolean,
                required: false,
                default: false
            },
            rules: {
                type: Array,
                required: false,
                default: () => []
            },
            type: {
                type: String,
                required: true,
                default: 'date'
            },
            usage: {
                type: String,
                required: false,
                default: 'startdate'
            },
            clearText: {
                type: String,
                default: DEFAULT_CLEAR_TEXT
            }
        },
        data() {
            return {
                language: uiHelper.getLanguage(),
                display: false,
                activeTab: 0,
                date: DEFAULT_DATE,
                time: this.usage == 'startdate' ? DEFAULT_START_TIME : DEFAULT_END_TIME,
                maxTime: ''
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            dateTimeFormat() {
                if (this.type == 'date') {
                    return DATE.SHORT
                } else {
                    return DATE.LONG
                }
            },
            defaultDateTimeFormat() {
                if (this.type == 'date') {
                    return DATE.SHORT
                } else {
                    return DATE.LONG
                }
            },
            formattedDatetime() {
                return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
            },
            selectedDatetime() {
                if (this.date && this.time) {
                    let datetimeString = this.date + ' ' + this.time
                    return datetimeString
                } else {
                    return null
                }
            },
            dateSelected() {
                return !this.date
            }
        },
        methods: {
            init() {
                if (this.value == null) {
                    return
                } else if (this.value == '') {
                    let iniDateTime = new Date()
                    this.date = moment(String(iniDateTime)).format(DATE.SHORT)
                    this.time = moment(String(iniDateTime)).format(DATE.TIME)
                } else {
                    this.date = moment(String(this.value)).format(DATE.SHORT)
                    this.time = moment(String(this.value)).format(DATE.TIME)
                }
            },
            okHandler() {
                this.resetPicker()
                this.date = moment(String(this.date)).format(DATE.SHORT)
                if (this.type == 'date') {
                    this.$emit('input', this.date)
                } else if (this.type == 'datetime') {
                    this.$emit('input', this.date + ' ' + this.time)
                }
            },
            resetPicker() {
                this.display = false
                this.activeTab = 0
                if (this.$refs.timer) {
                    this.$refs.timer.selectingHour = true
                }
            },
            showTimePicker() {
                if (this.type == 'datetime') {
                    this.activeTab = 1
                    this.maxTime = ''
                    if (this.maxDate != '' && this.maxDate.substr(0, 10) == this.date) {
                        this.maxTime = this.maxDate.substr(11, 8)
                        this.time = this.maxDate.substr(11, 8)
                    }
                }
            }
        },
        watch: {
            value: function () {
                this.init()
            }
        }
    }
</script>

<style lang="scss">
    .theme--dark.v-tabs.tabs_calendar > .v-tabs-bar {
        //background-color: var(--v-background-base);
    }

    .tabs_calendar .v-picker > .v-picker__body {
        //background: var(--v-background-base);
    }

    .tabs_calendar .v-picker.v-picker--time, .v-time-picker-clock {
        //background: var(--v-background-base);
    }
</style>
