import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper} from '@/util'
import {FOOTER_SEO} from "@/store/lookup.module";
import store from '@/store'

Vue.use(VueRouter)
Vue.use(VueMeta)
const homePage = {name: ROUTE_NAME.HOME}

const loginGuard = function (to, from, next) {
    uiHelper.isAuthenticated() ? next() : next(homePage)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {path: '/home/', redirect: '/', pathToRegexpOptions: {strict: true}},
        {path: '/home', redirect: '/', pathToRegexpOptions: {strict: true}},
        {
            path: '/',
            name: ROUTE_NAME.HOME,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/home.vue'),
            pathToRegexpOptions: {strict: true}
        },
        {
            path: '/sc',
            name: ROUTE_NAME.SHORTCUT,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/sc/shortcut.vue')
        },
        {
            path: '/reset-password',
            name: ROUTE_NAME.RESET_PASSWORD,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/resetPassword.vue')
        },
        {
            path: '/contact-verification',
            name: ROUTE_NAME.CONTACT_VERIFICATION,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/contactVerification.vue')
        },
        {
            path: '/register-mobile',
            name: ROUTE_NAME.REGISTER_MOBILE,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/registerMobile.vue')
        },
        {path: '/register', redirect: '/register/', pathToRegexpOptions: {strict: true}},
        {
            path: '/register/',
            name: ROUTE_NAME.REGISTER,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/register.vue'),
            pathToRegexpOptions: {strict: true}
        },
        {
            path: '/affiliate/referral',
            name: ROUTE_NAME.AFFILIATE_REFFERAL,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/affiliate/referral.vue')
        },
        {path: '/sportsbook', redirect: '/sportsbook/', pathToRegexpOptions: {strict: true}},
        {
            path: '/sportsbook/',
            component: () => import('@/views/sportsbook/sportsbookLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.SPORTSBOOK,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'sb' },
                    component: () => import('@/views/game/game.vue')

                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'sb' },
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {path: '/fishing', redirect: '/fishing/', pathToRegexpOptions: {strict: true}},
        {
            path: '/fishing',
            meta:{
                layout: 'layout-master'
            },
            component: () => import('@/views/fishing/fishingLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.FISHING,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'fishing'},
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.FISHING_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'fishing'},
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {path: '/crash', redirect: '/crash/', pathToRegexpOptions: {strict: true}},
        {
            path: '/crash',
            meta:{
                layout: 'layout-master'
            },
            component: () => import('@/views/crash/crashLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.CRASH,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'crash'},
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CRASH_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'crash'},
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {
            path: '/special',
            component: () => import('@/views/special/specialLayout.vue'),
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.SPECIAL,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/special/special.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/special/special.vue')
                }
            ]
        },
        {path: '/casino', redirect: '/casino/', pathToRegexpOptions: {strict: true}},
        {
            path: '/casino/',
            component: () => import('@/views/casino/casinoLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.CASINO,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'ld' },
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CASINO_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'ld' },
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {path: '/slot', redirect: '/slot/', pathToRegexpOptions: {strict: true}},
        {
            path: '/slot/',
            component: () => import('@/views/slot/slotLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.SLOT,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'rng' },
                    component: () => import('@/views/slot/slot.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SLOT_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'rng' },
                    component: () => import('@/views/slot/slot.vue')
                },
                {
                    path: ':provider/:gamename',
                    name: ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'rng' },
                    component: () => import('@/views/slot/slot.vue')
                },
                {
                    path: 'game/:gamename',
                    name: ROUTE_NAME.SLOT_BY_GAMENAME,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'rng' },
                    component: () => import('@/views/slot/slot.vue')
                }
            ]
        },
        {path: '/tablegames', redirect: '/tablegames/', pathToRegexpOptions: {strict: true}},
        {
            path: '/tablegames/',
            component: () => import('@/views/table/tableLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.TABLE_GAMES,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'table' },
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'table' },
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {path: '/lottery', redirect: '/lottery/', pathToRegexpOptions: {strict: true}},
        {
            path: '/lottery/',
            component: () => import('@/views/lottery/lotteryLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.LOTTERY,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'lottery' },
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'lottery' },
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {path: '/cricket', redirect: '/cricket/', pathToRegexpOptions: {strict: true}},
        {
            path: '/cricket/',
            component: () => import('@/views/cricket/cricketLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.CRICKET,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'cricket' },
                    component: () => import('@/views/game/game.vue')
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CRICKET_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: { providerSelected: 'cricket' },
                    component: () => import('@/views/game/game.vue')
                }
            ]
        },
        {
            path: '/playgame/:provider/:type',
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            meta: {layout: 'blank-layout-master'},
            component: () => import('@/views/game/play.vue')
        },
        {
            path: '/deposit_vendor',
            name: ROUTE_NAME.VENDOR_POPUP,
            meta: {layout: 'blank-layout-master'},
            component: () => import('@/views/bank/vendorPopUp.vue')
        },
        {
            path: '/bank',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/bank/bankLayout.vue'),
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: '/funds',
                    name: ROUTE_NAME.FUNDS,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/bank/funds.vue')
                },
                // {
                //     path: 'deposit',
                //     name: ROUTE_NAME.DEPOSIT,
                //     meta: {layout: 'layout-master'},
                //     component: () => import('@/views/bank/deposit.vue')
                // },
                {
                    path: 'deposit-success',
                    name: ROUTE_NAME.DEPOSIT_SUCCESS,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/bank/depositSuccess.vue')
                },
                // {
                //     path: 'withdrawal',
                //     name: ROUTE_NAME.WITHDRAWAL,
                //     meta: {layout: 'layout-master'},
                //     component: () => import('@/views/bank/withdrawal.vue')
                // },
                {
                    path: 'transaction',
                    name: ROUTE_NAME.TRANSACTION,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/bank/transaction.vue')
                },
                {
                    path: 'voucher',
                    name: ROUTE_NAME.CLAIM_VOUCHER,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/bank/claimVoucher.vue')
                },
                {
                    path: 'transfer',
                    name: ROUTE_NAME.TRANSFER,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/bank/transfer.vue')
                },
                {
                    path: 'vip',
                    name: ROUTE_NAME.VIP_PROFILE,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/vipPage.vue')
                },
                {
                    path: 'transfer-history',
                    name: ROUTE_NAME.TRANSFER_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/transactionHistory.vue')
                },
                {
                    path: 'betting-history',
                    name: ROUTE_NAME.BETTING_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/history/historyOverview.vue')
                },
                {
                    path: 'turnover-history',
                    name: ROUTE_NAME.TURNOVER_RECORD,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/turnover/historyOverview.vue')
                },
                {
                    path: 'transaction-history',
                    name: ROUTE_NAME.TRANSACTION_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/transactionHistory.vue')
                },
                {
                    path: 'bonus-history',
                    name: ROUTE_NAME.BONUS_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/transactionHistory.vue')
                }
            ]
        },
        {
            path: '/payment/process',
            name: ROUTE_NAME.PROCESS_TRANSACTION,
            meta: {layout: 'blank-layout-master'},
            component: () => import('@/views/bank/process.vue')
        },
        {
            path: '/payment/pay-secure',
            name: ROUTE_NAME.PAY_SECURE,
            meta: {layout: 'blank-layout-master'},
            component: () => import('@/views/bank/paysecure.vue')
        },
        {
            path: '/profile/menu',
            name: ROUTE_NAME.PROFILE_MENU,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/menu.vue'),
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            }
        },
        {
            path: '/profile/logout',
            name: ROUTE_NAME.PROFILE_LOGOUT,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/logout.vue')
        },
        {
            path: '/profile',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/profileLayout.vue'),
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'account',
                    name: ROUTE_NAME.PROFILE,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/account.vue')
                },
                {
                    path: 'personal',
                    name: ROUTE_NAME.PERSONAL,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/personal.vue')
                },
                {
                    path: 'message',
                    name: ROUTE_NAME.ANNOUNCEMENT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/announcement.vue')
                },
                {
                    path: 'change-password',
                    name: ROUTE_NAME.CHANGE_PASSWORD,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/changePassword.vue')
                },
                {
                    path: 'bank-account',
                    name: ROUTE_NAME.BANK_ACCOUNT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/bankAccount.vue')
                },
                {
                    path: 'inbox',
                    name: ROUTE_NAME.INBOX,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/inbox.vue')
                },
                {
                    path: 'rewards',
                    name: ROUTE_NAME.REWARDS,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/rewards.vue')
                }
            ]
        },
        {
            path: '/rewards',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/rewards/rewardLayout.vue'),
            children: [
                {
                    path: ':type',
                    name: ROUTE_NAME.REWARDS_BY_TYPE,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/rewards/rewards.vue')
                }
            ]
        },
        {path: '/promotion', redirect: '/promotion/', pathToRegexpOptions: {strict: true}},
        {
            path: '/promotion/',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/promotion/promotionLayout.vue'),
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '',
                    name: ROUTE_NAME.PROMOTION,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/promotion/promotion.vue')
                },
                {
                    path: 'in-progress',
                    name: ROUTE_NAME.PROMOTION_IN_PROGRESS,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/promotion/inprogress.vue'),
                    beforeEnter: (to, from, next) => {
                        loginGuard(to, from, next)
                    }
                },
                {
                    path: 'history',
                    name: ROUTE_NAME.PROMOTION_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/promotion/history.vue'),
                    beforeEnter: (to, from, next) => {
                        loginGuard(to, from, next)
                    }
                }
            ]
        },
        {
            path: '/info',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/info/infoLayout.vue'),
            children: [
                {
                    path: 'faq',
                    name: ROUTE_NAME.FAQ,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/info/faq.vue')
                },
                {
                    path: 'about-us',
                    name: ROUTE_NAME.ABOUT_US,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/info/aboutUs.vue')
                },
                {
                    path: 'contact-us',
                    name: ROUTE_NAME.CONTACT_US,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/info/contactUs.vue')
                },
                {
                    path: 'tnc',
                    name: ROUTE_NAME.TNC,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/info/tnc.vue')
                }
            ]
        },
        {
            path: '/affiliate/home',
            name: ROUTE_NAME.AFFILIATE_HOME,
            meta: {layout: 'layout-master'},
            component: () => import('@/views/affiliate/home.vue')
        },
        {
            path: '/affiliate',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/affiliate/affiliateLayout.vue'),
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'members',
                    name: ROUTE_NAME.AFFILIATE_MEMBER_REPORT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliate/memberReport.vue')
                },
                {
                    path: 'members-detail',
                    name: ROUTE_NAME.AFFILIATE_MEMBER_DETAIL_REPORT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliate/memberDetailReport.vue')
                },
                {
                    path: 'games-report',
                    name: ROUTE_NAME.AFFILIATE_GAME_REPORT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliate/gameReport.vue')
                },
                {
                    path: 'agent-report',
                    name: ROUTE_NAME.AFFILIATE_AGENT_REPORT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliate/agentReport.vue')
                },
                {
                    path: 'link',
                    name: ROUTE_NAME.AFFILIATE_LINK,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliate/link.vue')
                }
            ]
        },
        {
            path: '/affiliate/bank',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/affiliatePayment/bankLayout.vue'),
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'withdrawal',
                    name: ROUTE_NAME.AFFILIATE_WITHDRAWAL,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliatePayment/withdrawal.vue')
                },
                {
                    path: 'transaction',
                    name: ROUTE_NAME.AFFILIATE_TRANSACTION,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliatePayment/transaction.vue')
                },
                {
                    path: 'account',
                    name: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/affiliatePayment/account.vue')
                }
            ]
        },
        {
            path: '/luckywheel',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/rewards/WheelOfFortune.vue'),
            name: ROUTE_NAME.WOF
        },
        {
            path: '/mluckywheel',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/rewards/MobileWheelOfFortune.vue'),
            name: ROUTE_NAME.MWOF
        },
        {
            path: '/grab-coin',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/rewards/GrabCoin.vue'),
            name: ROUTE_NAME.GRAB_COIN
        },
        {
            path: '/referral',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/referral/referralLayout.vue'),
            children: [
                {
                    path: 'tier',
                    name: ROUTE_NAME.REFERRAL_TIER,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/referral/referralTier.vue')
                },
                {
                    path: 'summary',
                    name: ROUTE_NAME.REFERRAL_SUMMARY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/referral/referralSummary.vue')
                },
                {
                    path: 'report',
                    name: ROUTE_NAME.REFERRAL_REPORT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/referral/referralReport.vue')
                },
                {
                    path: 'history',
                    name: ROUTE_NAME.REFERRAL_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/referral/referralHistory.vue')
                },
            ]
        },
        {
            path: '/referral',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/bank/bankLayout.vue'),
            children: [
                {
                    path: 'bonus',
                    name: ROUTE_NAME.REFERRAL_BONUS,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/affiliateBonus.vue')
                }
            ]
        },
        {
            path: '/jayarank',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/vip/vipLayout.vue'),
            children: [
                {path: 'challenge', redirect: 'challenge/', pathToRegexpOptions: {strict: true}},
                {
                    path: 'challenge/',
                    name: ROUTE_NAME.VIP,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/vip/vip.vue'),
                    pathToRegexpOptions: {strict: true}
                }
            ]
        },
        {
            path: '/error',
            component: () => import('@/views/error/errorLayout.vue'),
            children: [
                {
                    path: 'ip-restriction',
                    name: ROUTE_NAME.ERROR_403,
                    meta: {layout: 'blank-layout-master'},
                    component: () => import('@/views/error/403.vue')
                },
                {
                    path: 'page-not-found',
                    name: ROUTE_NAME.ERROR_404,
                    meta: {layout: 'blank-layout-master'},
                    component: () => import('@/views/error/404.vue')
                }
            ]
        },
        {
            path: '/',
            redirect: {name: ROUTE_NAME.HOME}
        },
        {
            path: '*',
            redirect: {name: ROUTE_NAME.ERROR_404}
        }
    ],
    /* TO AUTO BACK TO TOP */
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.afterEach((to,from)=>{
    console.log('vuerouter to : '+to+  ' ,from : ' + from)
    let pageObj = {
        currentRoute : to.name
    }

   store.dispatch(`${FOOTER_SEO}`, {pageObj})
})

export default router
