import { announcementService } from '@/services'
import { locale, uiHelper } from '@/util'
import { CACHE_KEY } from '@/constants/constants'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'

//define module name
const MODULE_NAME = 'announcement/'

//define actions name
const A_LIST = 'getAnnouncementList'
const A_ANNOUNCEMENT = 'getAnnouncementDetail'
const A_RESET_ANNOUNCEMENT_DETAILS_STORE = 'resetAnnouncementDetailsStore'
const A_POPUP_LIST = 'popupList'

//define dispatch action
export const ANNOUNCEMENT_LIST = MODULE_NAME + A_LIST
export const ANNOUNCEMENT_DETAILS = MODULE_NAME + A_ANNOUNCEMENT
export const RESET_ANNOUNCEMENT_DETAILS_STORE = MODULE_NAME + A_RESET_ANNOUNCEMENT_DETAILS_STORE
export const POPUP_LIST = MODULE_NAME + A_POPUP_LIST

//define mutations name
const M_FAILURE = 'setFailure'
const M_LIST = 'setAnnouncementList'
const M_ANNOUNCEMENT = 'setAnnouncementDetail'
const M_RESET_ANNOUNCEMENT_DETAILS_STORE = 'resetAnnouncementDetailsStoreComplete'
const M_POPUP_LIST = 'setPopupList'

//init app state
const state = {
  popupAnnouncement: [],
  announcements: [],
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    lastPage: 0,
    totalItems: 0
  },
  announcement: {
    complete: false,
    success: false,
    code: 0,
    id: 0,
    type: '',
    title: {},
    messages: {},
    displayDateTime: '',
    subtype: '',
    startDate: new Date(),
    endDate: new Date(),
    previous: {
      id: 0,
      header: {}
    },
    next: {
      id: 0,
      header: {}
    }
  },
  errorMsg: ''
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_POPUP_LIST]({dispatch, commit}, {obj}) {
    dispatch(`${SHARED_LOADING}`, {}, {root: true})
    announcementService.getPopupListing(obj).then(
        data => {
          let result = data
          dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
          commit(M_POPUP_LIST, {result})
        },
        error => {
          dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
          commit(M_POPUP_LIST, error)
        }
    )
  },
  [A_LIST]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    announcementService.getAnnouncementListing(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_LIST, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },

  [A_RESET_ANNOUNCEMENT_DETAILS_STORE]({ commit }) {
    commit(M_RESET_ANNOUNCEMENT_DETAILS_STORE)
  },
  [A_ANNOUNCEMENT]({ dispatch, commit }, { processedData, id }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    announcementService.getAnnouncement(processedData, id).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_ANNOUNCEMENT, data)
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  }
}

const mutations = {
  [M_POPUP_LIST](state, {result}) {
    let p = []

    if (result.success) {
      let d = result.data

      d.forEach(announcement => {
        let announcementTitleLocale = []
        let announcementMessageLocale = []

        announcement.title.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            announcementTitleLocale.push({
              language: Object.keys(language)[i],
              title: Object.values(language)[i]
            })
          }
        })

        announcement.messages.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            announcementMessageLocale.push({
              language: Object.keys(language)[i],
              message: Object.values(language)[i]
            })
          }
        })

        p.push({
          id: announcement.id,
          type: announcement.announcement_type.toLowerCase(),
          titleLocale: announcementTitleLocale,
          messageLocale: announcementMessageLocale,
          displayTime: announcement.display_time,
          subAnnouncement: announcement.sub_announcement
        })
      })

      state.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }
    }
    state.popupAnnouncement = p
  },
  [M_LIST](state, { result }) {
    let p = []

    if (result.success) {
      let d = result.data

      d.forEach(announcement => {
        let announcementTitleLocale = []
        let announcementMessageLocale = []

        announcement.title.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            announcementTitleLocale.push({
              language: Object.keys(language)[i],
              title: Object.values(language)[i]
            })
          }
        })

        announcement.messages.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            announcementMessageLocale.push({
              language: Object.keys(language)[i],
              message: Object.values(language)[i]
            })
          }
        })

        p.push({
          id: announcement.id,
          type: announcement.announcement_type.toLowerCase(),
          titleLocale: announcementTitleLocale,
          messageLocale: announcementMessageLocale,
          displayTime: announcement.display_time,
          subAnnouncement: announcement.sub_announcement
        })
      })

      state.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }
      uiHelper.setLocalStorage(`${CACHE_KEY.ANNOUNCEMENT}_${uiHelper.getPlatform()}`, p, 10)
    }
    state.announcements = p
  },
  [M_ANNOUNCEMENT](state, data) {
    let d = data.data
    let n = data.navigator
    let s = state.announcement

    s.complete = true
    s.success = data.success
    s.code = data.code
    //Navigator
    s.previous.id = n.prev != null ? n.prev.id : 0
    s.previous.header = n.prev != null ? locale.getName(n.prev.header) : null
    s.next.id = n.next != null ? n.next.id : 0
    s.next.header = n.next != null ? locale.getName(n.next.header) : null

    //Announcement Details
    s.id = d.id
    s.type = locale.getMessage(`AnnouncementType.${d.announcement_type}`)
    s.title = locale.getName(d.title)
    s.messages = locale.getName(d.messages)
    s.displayDateTime = d.display_time
    s.subtype = locale.getArrayMessage('enumAnnouncementSubtype[0]', d.sub_announcement)
    s.startDate = d.start_date
    s.endDate = d.expired_date
  },

  [M_FAILURE](state, error) {
    state.errorMsg = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
