<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="floating-right" @click="setShowContactUsPanel()" @mouseleave="hoverContactUsPanel()" @focusout="hideShowContactUsPanel()" tabindex="0">
      <div @mouseenter="hoverContactUsPanel()" class="floating-button">{{ $t(`label.contactUs`) }}</div>
      <div v-if="showRewardPanel" class="contactUs-panel d-flex flex-column align-center justify-center">
        <div class="contactUs-header d-flex justify-space-around align-center">
          <div><img class="contactUs-header-icon" src="/static/svg/layout/customer-support.svg" alt="contactUs icon" /></div>
          <div>{{ $t(`floating.contactUsHeader`) }}</div>
        </div>
        <a href="https://direct.lc.chat/14469159/" rel="nofollow" target="_blank" class="contactUs-item livechat-item d-flex align-center">
          <img class="contactUs-icon" :src="`/static/svg/layout/live-chat.svg`" alt="livechat support" />
          <div>{{ $t(`label.liveChat`) }}</div>
        </a>

        <a href="https://wa.me/601120925637" rel="nofollow" target="_blank" class="contactUs-item email-item d-flex align-center">
          <img class="contactUs-icon" :src="`/static/svg/layout/whatsapp.png`" alt="email support" />
          <div>{{ $t(`label.whatsapp`) }}</div>
        </a>

        <a href="https://t.me/Jaya9Officials" rel="nofollow" target="_blank" class="contactUs-item telegram-item d-flex align-center">
          <img class="contactUs-icon" :src="`/static/svg/layout/telegram.svg`" alt="telegram support" />
          <div>Jaya9</div>
        </a>
        <a href="https://www.facebook.com/jaya9official" rel="nofollow" target="_blank"  class="contactUs-item metaMessenger-item d-flex align-center  " >
          <img class="contactUs-icon" src="/static/svg/layout/metaMessenger.png" alt="messenger"/>
          <div>{{ $t(`label.metaMsger`) }}</div>
        </a>
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" class="fab-contactUs">
      <div v-if="moreContactUs" class="d-flex flex-column">
        <a href="https://direct.lc.chat/14469159/" rel="nofollow" target="_blank" class="mx-auto">
          <img class="contactUs-icon" src="/static/svg/layout/chat-mobile.svg" alt="livechat" />
        </a>
        <a href="https://wa.me/6001120925637" rel="nofollow" target="_blank" class="pngImageAnchor">
          <img class="pngImage" src="/static/svg/layout/whatsapp.png" alt="whatsapp" />
        </a>

        <a href="https://t.me/Jaya9Officials" rel="nofollow" target="_blank">
          <img class="contactUs-icon" src="/static/svg/layout/telegram-mobile.svg" alt="telegram" />
        </a>

        <a href="https://www.facebook.com/jaya9official" rel="nofollow" target="_blank" class="pngImageAnchor">
          <img class="pngImage" src="/static/svg/layout/metaMessenger.png" alt="messenger"/>
        </a>

      </div>
      <div @click="setMoreContactUs()" class="contactUs-icon">
        <img class="contactUs-icon" :src="moreContactUs ? `/static/svg/layout/cancel-mobile.svg` : `/static/svg/layout/chat-mobile.svg`" alt="livechat support" />
      </div>
    </div>
  </div>
</template>

<script>
import { uiHelper, locale } from '@/util'
import i18n from '@/plugins/i18n'
import { ROUTE_NAME } from '@/constants/route.constants'
import { DevicePlatform } from '@/constants/enums'

export default {
  name: 'floatingus',
  props: {},
  data: () => ({
    i18n,
    showRewardPanel: false,
    active: false,
    moreContactUs: false
  }),
  methods: {
    hoverContactUsPanel() {
      if (!this.active) this.showRewardPanel = !this.showRewardPanel
    },
    setShowContactUsPanel() {
      if (!this.active && this.showRewardPanel) {
        this.active = true
        this.showRewardPanel = true
      } else {
        this.active = !this.active
        this.showRewardPanel = !this.showRewardPanel
      }
    },
    hideShowContactUsPanel() {
      this.active = false
      this.showRewardPanel = false
    },
    setMoreContactUs() {
      this.moreContactUs = !this.moreContactUs
    },
    openLiveChat() {
      this.$parent.openLiveChat()
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  }
}
</script>

<style lang="scss">
.fab-contactUs {
  position: fixed;
  bottom: 90px;
  right: 20px;
  bottom: 8%;
  right: 0;
  z-index: 5;
  text-decoration: none;

  .contactUs-icon {
    width: 72px;
    height: 72px;
    cursor: pointer;
  }

  .pngImage{
    width:63px;
    height: 63px;
  }

  .pngImageAnchor{
    margin:0  auto;
  }

  .mobile-livechat-icon {
    width: 61px !important;
    height: 61px !important;
    cursor: pointer;
  }
}

.floating-right {
  position: fixed;
  display: flex;
  z-index: 5;
  right: 0;
  top: calc(50%);

  &:focus {
    &::after {
      width: 100%;
      height: 100%;
      display: absolute;
      z-index: 4;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .floating-button {
    //height: 230px;
    background-image: linear-gradient(to right, #f48020, #f05724);
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 12px 8px;
    top: 0;
    cursor: pointer;
  }





  .contactUs-panel {
    background-color: #f5f5f5;

    .contactUs-header {
      font-size: 1em;
      max-width: 212px;
      text-align: center;
      padding: 0px 12px;

      .contactUs-header-icon {
        width: 80px;
        height: 80px;
      }
    }

    .contactUs-item {
      width: 100%;
      padding: 8px;
      gap: 8px;
      text-decoration: none;
      cursor: pointer;

      .contactUs-icon {
        width: 32px;
        height: 32px;
      }
    }

    .livechat-item {
      background-image: linear-gradient(#f48020, #f05724);
      color: #ffffff;
    }

    .telegram-item {
      background-image: linear-gradient(#45b4e4, #0092c8);
      color: #ffffff;
    }

    .email-item {
      background-image: linear-gradient(#70c59e, #368f6b);
      color: #ffffff;
    }

    .metaMessenger-item {
      background-image: linear-gradient(to bottom, #3A5795 0%, #2B4886 100%);
      color: #ffffff;
    }
  }
}
</style>
