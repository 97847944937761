import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { SHARED } from '@/constants/constants'
import '@/filters'
import '@/plugins/vuetify'
import './styles.scss'
import jQuery from 'jquery'
import LayoutMaster from '@/components/layout/LayoutMaster.vue'
import BlankLayoutMaster from '@/components/layout/BlankLayoutMaster.vue'
import AppFormField from '@/components/FormField.vue'
import AppButton from '@/components/Button.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import AppDialog from '@/components/dialog.vue'
import AppDateTimePicker from '@/components/DateTimePicker.vue'
import ProfileSheet from '@/components/profileSheet.vue'
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'
import Siema from 'vue2-siema'
import _ from 'lodash'
import Flicking from "@egjs/vue-flicking";
import VueCaptchaCode from "@johnnyguan/vue-captcha-code";
import "@egjs/vue-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/vue-flicking/dist/flicking-inline.css";


Vue.use(Flicking);
Vue.prototype.$eventHub = new Vue()
Vue.use(Siema)
Vue.use(VueClipboard)
Vue.use(VueAnalytics, {
  id: SHARED.GA_TRACKING_CODE,
  router
})
Vue.use(VueCaptchaCode)
Vue.component('layout-master', LayoutMaster)
Vue.component('blank-layout-master', BlankLayoutMaster)
Vue.component('app-form-field', AppFormField)
Vue.component('app-button', AppButton)
Vue.component('button-primary', ButtonPrimary)
Vue.component('app-dialog', AppDialog)
Vue.component('app-date-time-picker', AppDateTimePicker)
Vue.component('profile-sheet', ProfileSheet)

Vue.config.productionTip = false
export const eventBus = new Vue()
Vue.prototype.$eventHub = new Vue()
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
