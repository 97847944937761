import { affiliateService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SHARED } from '@/constants/constants'

const MODULE_NAME = 'affiliateBonus/'

//define actions name
const A_AFFILIATE_DOWNLINE_LIST = 'getDownlineList'
const A_AFFILIATE_DOWNLINE_DETAIL = 'getDownlineDetail'
const A_AFFILIATE_UPLINE_REFERRAL = 'getUplineReferral'
const A_AFFILIATE_CLAIM_BONUS = 'claimBonus'
const A_AFFILIATE_BONUS_DETAIL = 'getAffiliateOneTimeBonusDetail'
const A_AFFILIATE_RESET_ACTION_CLAIM_BONUS = 'resetClaimBonus'

//define dispatch action
export const AFFILIATE_DOWNLINE_LIST = MODULE_NAME + A_AFFILIATE_DOWNLINE_LIST
export const AFFILIATE_DOWNLINE_DETAIL = MODULE_NAME + A_AFFILIATE_DOWNLINE_DETAIL
export const AFFILIATE_UPLINE_REFERRAL = MODULE_NAME + A_AFFILIATE_UPLINE_REFERRAL
export const AFFILIATE_CLAIM_BONUS = MODULE_NAME + A_AFFILIATE_CLAIM_BONUS
export const AFFILIATE_ONE_TIME_BONUS_DETAIL = MODULE_NAME + A_AFFILIATE_BONUS_DETAIL
export const AFFILIATE_RESET_CLAIM_BONUS = MODULE_NAME + A_AFFILIATE_RESET_ACTION_CLAIM_BONUS

//define mutations name
const M_DOWNLINE_LIST = 'setDownlineList'
const M_DOWNLINE_DETAIL = 'setDownlineDetail'
const M_UPLINE_REFERRAL = 'setUplineReferral'
const M_CLAIM_ACTION = 'setClaimBonus'
const M_AFFILIATE_BONUS_DETAIL = 'setAffiliateBonusDetail'
const M_RESET_ACTION = 'resetClaimAction'

const state = {
  affiliateDownlineList: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      totalItems: 0
    }
  },
  affiliateBonusDetail: {
    min_deposit: '',
    min_valid_bet: '',
    min_successful_referral: '',
    affiliate_bonus_amount: '',
    downline_bonus_amount: '',
    turnover: '',
    admin_fee: '',
    unlock_bonus: '',
    currency: '',
    status: ''
  },
  affiliateDownlineDetail: {
    required_deposit: 0,
    current_deposit: 0,
    required_valid_bet: 0,
    current_valid_bet: 0,
    bonus_amount: 0,
    is_claimed: 0,
    process_status: 0,
    expired_at: '',
    is_expired: ''
  },
  affiliateUplineReferral: {
    current_referral: 0,
    required_referral: 0,
    accepted_referral: 0,
    is_claimed: 0,
    unlock_bonus: 0,
    referral_process_status: 0
  },
  affiliateClaimBonus: {
    complete: false,
    success: false,
    code: 0
  }
}

const getters = {}

const actions = {
  [A_AFFILIATE_DOWNLINE_LIST]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAffiliateDownlineListing(filter).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DOWNLINE_LIST, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DOWNLINE_LIST, error)
      }
    )
  },
  [A_AFFILIATE_BONUS_DETAIL]({ commit, dispatch }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAffiliateBonusDetail().then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AFFILIATE_BONUS_DETAIL, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AFFILIATE_BONUS_DETAIL, { error })
      }
    )
  },
  [A_AFFILIATE_DOWNLINE_DETAIL]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAffiliateDownlineDetail().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DOWNLINE_DETAIL, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DOWNLINE_DETAIL, error)
      }
    )
  },
  [A_AFFILIATE_UPLINE_REFERRAL]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAffiliateUplineReferral().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPLINE_REFERRAL, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPLINE_REFERRAL, error)
      }
    )
  },
  [A_AFFILIATE_CLAIM_BONUS]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.affiliateClaimBonus().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CLAIM_ACTION, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CLAIM_ACTION, error)
      }
    )
  },
  [A_AFFILIATE_RESET_ACTION_CLAIM_BONUS]({ commit }) {
    commit(M_RESET_ACTION)
  }
}

const mutations = {
  [M_DOWNLINE_LIST](state, { result }) {
    if (result.data != null) {
      let d = result.data
      let p = result.pagination
      state.affiliateDownlineList.data = d
      state.affiliateDownlineList.pagination = p
    } else {
      state.affiliateDownlineList.data = []
      state.affiliateDownlineList.pagination = {}
    }
  },
  [M_AFFILIATE_BONUS_DETAIL](state, { data }) {
    let d = data.data
    state.affiliateBonusDetail.min_deposit = d.min_deposit
    state.affiliateBonusDetail.min_valid_bet = d.min_valid_bet
    state.affiliateBonusDetail.min_successful_referral = d.min_success_referral
    state.affiliateBonusDetail.affiliate_bonus_amount = d.affiliate_bonus_amount
    state.affiliateBonusDetail.downline_bonus_amount = d.downline_bonus_amount
    state.affiliateBonusDetail.turnover = d.turnover_requirement
    state.affiliateBonusDetail.admin_fee = d.admin_fee
    state.affiliateBonusDetail.unlock_bonus = d.unlock_bonus
    state.affiliateBonusDetail.currency = d.currency
    state.affiliateBonusDetail.status = d.status
  },
  [M_DOWNLINE_DETAIL](state, { result }) {
    let d = result.data
    if (d != null) {
      state.affiliateDownlineDetail.required_deposit = d.required_deposit
      state.affiliateDownlineDetail.current_deposit = d.current_deposit
      state.affiliateDownlineDetail.required_valid_bet = d.required_valid_bet
      state.affiliateDownlineDetail.current_valid_bet = d.current_valid_bet
      state.affiliateDownlineDetail.bonus_amount = d.bonus_amount
      state.affiliateDownlineDetail.is_claimed = d.is_claimed
      state.affiliateDownlineDetail.process_status = d.process_status
      state.affiliateDownlineDetail.expired_at = d.expired_at
      state.affiliateDownlineDetail.is_expired = d.is_expired
    }
  },
  [M_UPLINE_REFERRAL](state, { result }) {
    let d = result.data
    if (d != null) {
      state.affiliateUplineReferral = {
        current_referral: d.current_referral,
        required_referral: d.required_referral,
        accepted_referral: d.accepted_referral,
        is_claimed: d.is_claimed,
        unlock_bonus: d.unlock_bonus,
        referral_process_status: d.referral_process_status
      }
    }
  },
  [M_CLAIM_ACTION](state, { result }) {
    state.affiliateClaimBonus.complete = true
    state.affiliateClaimBonus.success = result.success
    state.affiliateClaimBonus.code = result.success
  },
  [M_RESET_ACTION](state) {
    state.affiliateClaimBonus.complete = false
    state.affiliateClaimBonus.success = false
    state.affiliateClaimBonus.code = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
