import {locale} from './localization-helper'
import {uiHelper} from "@/util/ui-helper";
import {SESSION} from "@/constants/constants";

export const affDomainHelper = {
    getAffCodeBasedOnDomain
}

function getAffCodeBasedOnDomain(hostName, af = "") {
    let agent = "";
    //SUPERJAYA7
    if (hostName.indexOf("jaya7") > -1) {
        agent = "AG000001"
    } else if (hostName.indexOf("jaya8") > -1) {
        agent = "AG000002"
    } else if (hostName.indexOf("jaya6") > -1) {
        agent = "AG000003"
    }else if(hostName.indexOf("jaya21") > -1){
        agent = "AG000005"
    }else if(/\bjaya2\b/.test(hostName)){
        agent = "AG000004"
    }
    return agent === "" ? af : agent;
}

