<template>
  <v-footer class="footer" color="footer_color">
    <div class="footer_wrapper">
      <div class="footer-container-1">
        <div class="payment-methods">
          <div class="footer-title mb-2">{{ $t(`footer.paymentMethods`) }}</div>
          <div class="d-flex flex-wrap payment-methods-icons">
            <template v-for="(paymentMethod, i) in paymentMethods">
              <v-img class="footer-icon" :key="i" :src="`/static/image/footer/${paymentMethod.img}.svg`" alt :contain="true"></v-img>
              <!-- <v-hover v-slot="{ hover }" class="mr-3" :key="paymentMethod">
                  <v-img max-width="67px" max-height="24px" :src="`/static/image/footer/${paymentMethod.img}.png`" alt>
                      <v-img v-if="hover" max-width="67px" max-height="24px" :src="`/static/image/footer/${paymentMethod.img}-color.png`" alt />
                  </v-img>
              </v-hover> -->
            </template>
          </div>
        </div>

        <div class="d-flex flex-column customer-support">
          <label class="footer-title">{{ $t(`footer.customerSupport`) }}</label>
          <div class="d-flex">
            <v-img max-width="32px" max-height="32px" src="/static/image/footer/icon-24hr.svg" alt />
            <div class="d-flex flex-column column-gap justify-center align-center">
              <div class="footer-support secondary--text">
                <a href="https://direct.lc.chat/14469159/" style="text-decoration: none" target="_blank">{{ $t(`footer.chatNow`) }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="community">
          <v-row no-gutters class="mb-2">
            <label class="footer-title">{{ $t(`footer.certification`) }}</label>
          </v-row>
          <v-row no-gutters>
            <a href="https://casinosanalyzer.com/online-casinos"><img height="32" src="/static/image/footer/gaming_Casino_Analyzer.svg" alt /></a>
            <img height="32" src="/static/image/footer/gaming_iTechLabs.svg" alt class="ml-2" />
          </v-row>
        </div>

        <div class="customer-support">
          <div class="footer-title mb-2">{{ $t(`footer.communityWebsite`) }}</div>
          <div class="d-flex flex-wrap payment-methods-icons">
            <template v-for="(responsbileGambling, i) in responsbileGamblingLink">
              <v-img :key="i" class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}.png`" alt></v-img>
              <!-- <v-hover v-slot="{ hover }" class="mr-3" :key="responsbileGambling">
                  <v-img class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}.png`" alt>
                      <v-img v-if="hover" class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}-color.png`" alt />
                  </v-img>
              </v-hover> -->
            </template>
          </div>
        </div>

        <div :class="$vuetify.breakpoint.mdAndUp ? 'gaming-license' : ''">
          <div class="footer-title mb-2">{{ $t(`footer.gamingLicense`) }}</div>
          <div class="d-flex flex-wrap payment-methods-icons">
            <img height="32" src="/static/image/footer/gaming_license.webp" alt />
          </div>
        </div>
      </div>

      <v-col cols="12" class="py-5">
        <v-divider></v-divider>
      </v-col>

      <v-row no-gutters class="text-left">
        <h1 style="font-size: 1.17em" v-html="txtSEO_Title"></h1>
        <p id="aaa" class="seo_text" v-html="txtSEO_Desc1">
          <!--          {{$t(`footer.trustedPremiumOnlineCasinoDesc_1`)}}<br/>-->
        </p>
        <p id="bbb" class="seo_text" v-html="txtSEO_Desc2">
          <!--          {{$t(`footer.trustedPremiumOnlineCasinoDesc_2`)}}<br/>-->
        </p>
      </v-row>

      <v-col cols="12" class="py-2">
        <v-divider></v-divider>
      </v-col>

      <v-row no-gutters justify="space-between" class="align-center cols">
        <v-row no-gutters class="mb-2">
          <img style="align-self:end" class="footer-logo" src="/static/image/home/logo3.png" alt="JAYA9 cricket exchange" />
          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp" class="footer-divider ml-5"></v-divider>
        </v-row>
        <v-col cols="12" :md="4" class="faq-container no-gutters">
          <v-row class="faq-wrapper">
            <router-link :to="{ name: routeName.FAQ }" class="footer-title">{{ $t(`label.responbileGaming`) }}</router-link>
            <router-link :to="{ name: routeName.TNC }" class="footer-title">{{ $t(`label.tnc`) }}</router-link>
            <router-link :to="{ name: routeName.ABOUT_US }" class="footer-title">{{ $t(`label.aboutUs`) }}</router-link>
          </v-row>
          <!-- <div v-show="$vuetify.breakpoint.smAndDown" class="title_color--text font-weight-bold">{{$t(`footer.bestQualityPlatform`)}}</div> -->
          <div class="copyright" style="color: var(--v-description-darken3)">{{ footerCopyRight }}</div>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
import NavOnePay from '@/assets/svg/onePay.svg'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { locale, uiHelper } from '@/util'
import { SHARED, CACHE_KEY } from '@/constants/constants'
import { SHARED_LOADING } from '@/store/shared.module'
import _ from 'lodash'
import router from '@/router'
import { FOOTER_SEO, RESET_FOOTER_SEO } from '@/store/lookup.module'

export default {
  name: 'appFooter',
  props: {},
  components: {
    NavOnePay
  },
  data: () => ({
    txtSEO_Title: '',
    txtSEO_Desc1: '',
    txtSEO_Desc2: '',
    isViewableAll: false,
    footerProvider: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    supportedLanguage: SHARED.SUPPORTED_LANGUAGES,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    routeName: ROUTE_NAME,
    footerLink: [
      // {
      //   link: '/customer-service',
      //   text: locale.getMessage(`label.customerROUTE_NAMEService`)
      // },
      {
        routeName: ROUTE_NAME.TNC,
        text_key: 'termsAndConditions'
      },
      {
        routeName: ROUTE_NAME.ABOUT_US,
        text_key: 'aboutUs'
      },
      {
        routeName: ROUTE_NAME.FAQ,
        text_key: 'faq'
      },
      {
        routeName: ROUTE_NAME.CONTACT_US,
        text_key: 'contactUs'
      }
    ],
    socialMediaLink: [
      {
        name: 'facebook'
      },
      {
        name: 'instagram'
      },
      {
        name: 'twitter'
      },
      {
        name: 'youtube'
      },
      {
        name: 'pinterest'
      }
    ],
    responsbileGamblingLink: [
      {
        img: 'safe2'
      },
      {
        img: 'safe1_2'
      }
    ],
    paymentMethods: [
      {
        img: 'payment-bank'
      }
      // ,
      //   {
      //     img: 'payment-bkash',
      //   },
      //   {
      //     img: 'payment-nagad',
      //   },
      //   {
      //     img: 'payment-rocket',
      //   }
    ],
    footerCopyRight: format(locale.getMessage(`message.copyRight`), new Date().getFullYear())
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    SEOText() {
      return this.$store.state.lookup.footerSEO.title
    }
  },
  created() {
    this.setFooterSEO()
  },
  watch: {
    ProviderTypes() {
      this.footerProvider = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
      return this.$store.state.game.ProviderTypes
    },
    SEOText() {
      let stateSEOTexts = this.$store.state.lookup.footerSEO
      this.txtSEO_Title = stateSEOTexts.title
      this.txtSEO_Desc1 = stateSEOTexts.desc_1
      this.txtSEO_Desc2 = stateSEOTexts.desc_2
      if (stateSEOTexts.isReload) {
        this.$store.dispatch(`${RESET_FOOTER_SEO}`)
      }
    }
  },
  methods: {
    setFooterSEO() {
      /*use for first load ,since footer is masterpage. upcoming data of footer is using watch() triggered by vuerouter's aftereach */
      let pageObj = {
        currentRoute: this.$route.name
      }
      console.log('footer currenRoute : ' + pageObj.currentRoute)
      let currentDomain = window.location.hostname
      console.log('footer currentdomain : ' + currentDomain)
      // if (/\bjaya9.vip|jaya9.win\b/.test(currentDomain)) {
      let seoText = uiHelper.getFooterSEOVip(pageObj)
      this.updateFooterSEO(seoText)
      // } else {
      //   let seoText = uiHelper.getFooterSEO(pageObj)
      //   this.updateFooterSEO(seoText)
      // }
    },
    updateFooterSEO(seoText) {
      this.txtSEO_Title = seoText.title
      this.txtSEO_Desc1 = seoText.desc_1
      this.txtSEO_Desc2 = seoText.desc_2
    },
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED_LOADING}`)
      locale.setCurrentLanguage(language)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    goToFirstCagayan() {
      window.open('https://www.firstcagayan.com/zh-hant#2', '_blank')
    },
    goToPagcor() {
      window.open('http://www.pagcor.ph/index.php', '_blank')
    }
  }
}
</script>

<style lang="scss">
.v-footer {
  position: unset;

  .footer-title {
    font-size: 12px;
    font-family: 'ReadexPro-Bold';
  }

  .footer_wrapper {
    max-width: 1240px;
    margin: 30px auto;
    font-size: 12px;

    .footer-container-1 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 20px;

      .column-gap {
        gap: 4px;
      }
    }
  }

  .footer-divider {
    color: var(--v-divider-base);
  }

  .footer-logo {
    width: 120px;
  }

  .footer-disabled {
    filter: grayscale(100%);
  }

  .footer-icon {
    max-width: 32px;
    max-height: 32px;
  }

  .seo_text {
    font-size: 14px;
    color: var(--v-description-darken3);
  }

  .footer-link-divider {
    border-color: #3a3a3a;
  }

  .footer-support:hover {
    color: var(--v-primary-base);
  }

  .gaming-license {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }

  .payment-methods-icons {
    gap: 12px;
  }

  .faq-container {
    text-align: end;

    .faq-wrapper {
      gap: 12px;
      color: var(--v-primary-base);
      margin: 0px 0px 12px 0px;
      justify-content: end;
    }
  }
}

@media (max-width: 599px) {
  .footer {
    margin-bottom: 50px;

    .footer_wrapper {
      .footer-container-1 {
        .customer-support {
          width: 50%;
        }

        .community {
          width: calc(50% - 10px);
        }

        .payment-methods {
          width: calc(45% - 10px);
        }

        .payment-methods-icons {
          gap: 20px;
        }
      }

      .faq-container {
        text-align: start;

        .faq-wrapper {
          justify-content: start;
        }
      }
    }
  }
}
</style>
