<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import {
  uiHelper,
  locale
} from '@/util'
import {
  SHARED,
  CACHE_KEY
} from '@/constants/constants'
import {
  GAME_PROVIDERS_TYPE_CONFIG
} from '@/store/game.module'

export default {
  name: 'App',
  metaInfo: {
    title: `${locale.getMessage('meta.default_title')}`,
    titleTemplate: `%s`,
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'description',
      content: `${locale.getMessage('meta.default_description')}`
    }, {
      name: 'keyword',
      content: `${locale.getMessage('meta.default_keyword')}`
    }]
  },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.BLANK_LAYOUT
    }
  },
  data: () => ({
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
  }),
  created() {
    this.browserDetec()
    this.getGameProviderTypeInfo()
    this.initializePageLanguage()
  },
  methods: {
    browserDetec() {
      let browser_detail = navigator.userAgent
      if (
          browser_detail.indexOf('Opera') > -1 ||
          browser_detail.indexOf('OPR') > -1 ||
          browser_detail.indexOf('MSIE') > -1 ||
          browser_detail.indexOf('MZBrowser') > -1 ||
          browser_detail.indexOf('UBrowser') > -1 ||
          browser_detail.indexOf('.NET') > -1
      ) {
        window.location.href = '/static/html/error/browser-not-support.html'
      }
    },
    getGameProviderTypeInfo() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, {
        providerTypeConfigObj
      })
    },
    initializePageLanguage() {
      let language = uiHelper.getCookie('language')
      if (language == null) {
        language = SHARED.DEFAULT_LANGUAGE
      }
      locale.setCurrentLanguage(language)
      uiHelper.setCookie('language', language)
    }
  }
}
</script>

<style lang="scss">
// html {
//   scroll-behavior: smooth;
//   overflow-y: auto !important;
// }

* {
  word-break: normal;
}

.body {
  background-color: var(--v-base-base);
  scroll-behavior: smooth;
  overflow-y: auto;
  margin-top: 115px;
}

@media (max-width: 959px) {
  .body {
    margin-top: 45px;
  }
}

.full-width {
  width: 100%;
}

.full-height,
html,
body,
#app,
.page {
  height: 100%;
}

.dialog-card {
  overflow-x: hidden;
}

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}

/* Table CSS */
// .v-data-table__wrapper {
//   table {
//     .v-data-table-header-mobile {
//       display: none;
//     }
//     .v-data-table-header {
//       tr {
//         background-color: var(--v-base_3-base);
//         th {
//           span {
//             color: var(--v-base-base);
//           }
//         }
//       }
//     }
//     tbody {
//       tr:nth-child(2n) {
//         background-color: #2d3454 !important;
//       }
//     }
//     td,
//     th {
//       border-bottom: 0px !important;
//     }
//   }
// }

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}

.button-primary {
  position: relative;
  min-height: 38px;
  min-width: 120px;
  border-radius: 3px;
  background: linear-gradient(to right, #fbc103 0%, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
  background-position: -50% 0;
  box-shadow: 0 5px 10px 0 rgb(6 8 15 / 10%),
  inset 0 0 3px 0 #ffdd8f;
  transition: background-position .3s ease-in,
  box-shadow .5s ease-out .1s,
  text-shadow .3s ease-in .1s;
  color: #ffffff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('/static/btn-light.png') no-repeat center top;
    background-position: 0 0;
    background-size: 100%;
  }

  &:hover {
    transition-duration: 0.6s;
    transition-delay: 0.3s;
    background-position: 0 0;
    background: linear-gradient(to right, #fbc103 0%, #f79604 15%, #f79604 65%, #fbc103 100%);
    box-shadow: 0 2px 2px 1px rgb(6 8 15 / 10%), 0 4px 4px 1px rgb(6 8 15 / 10%), 0 8px 8px 1px rgb(6 8 15 / 10%), 0 16px 16px 1px rgb(6 8 15 / 10%), 0 32px 32px 1px rgb(6 8 15 / 10%), inset 0 0 3px 0 #ffdd8f;
    text-shadow: 0 1px 3px #967302;
  }

}
</style>
