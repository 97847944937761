<template>
  <div class="full-width">
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : 'px-5'">
      <v-window v-model="step">
        <v-window-item :value="0">
          <v-form ref="registerForm1">
            <v-card-text class="ma-0 pa-0">
              <app-form-field
                ref="username"
                :enabledLabel="true"
                v-model.trim="registerObj.memberCode"
                :label="$t(`field.username`)"
                :placeholder="$t(`fieldHint.fillUpHere`)"
                :tooltip="$t(`fieldHint.username`)"
                :errorMsg="userNameErrorMsg"
                :blur="checkUsername"
                :rules="validator.usernameRules()"
              ></app-form-field>
              <app-form-field
                :enabledLabel="true"
                v-model.trim="registerObj.password"
                :label="$t(`field.password`)"
                :placeholder="$t(`fieldHint.fillUpPwdHere`)"
                :tooltip="$t(`fieldHint.password`)"
                :rules="validator.passwordRules()"
                :allowShowPassword="false"
                type="password"
              ></app-form-field>
              <app-form-field
                :enabledLabel="true"
                v-model.trim="confirmedPassword"
                :label="$t(`field.confirmPassword`)"
                :placeholder="$t(`fieldHint.confirmPassword`)"
                :rules="validator.confirmPasswordRules(confirmedPassword, registerObj.password)"
                :allowShowPassword="false"
                type="password"
              ></app-form-field>
              <app-form-field
                :enabledLabel="true"
                :label="$t(`field.currency`)"
                v-model.trim="registerObj.currency"
                :disabled="currencyList.length == 1"
                type="select"
                :rules="validator.dropDownListRules()"
                :items="currencyList"
              ></app-form-field>
            </v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <app-button class="dialog-button" :action="this.nextPage" :title="$t(`button.next`)"></app-button>
                <!-- <v-btn height="auto" @click="step++" color="black" depressed class="primary-button theme-button text-capitalize pa-3 font-weight-bold yellow--text subtitle-1">{{ $t(`button.next`) }}
                </v-btn> -->
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="1">
          <v-form ref="registerForm2">
            <v-card-text class="ma-0 pa-0">
              <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                {{ $t(`field.phoneNumber`) }}
                <span class="red--text ml-1">*</span>
              </label>
              <v-row no-gutters>
                <v-col cols="5">
                  <!-- <v-select elevation="2" class="input-field mr-2" item-text="text" item-value="value" :items="mobileNumberPrefixList" dense outlined></v-select> -->
                  <app-form-field
                    v-model.trim="registerObj.currency"
                    :placeholder="mobileNumberPrefixList[0].text"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="mobileNumberPrefixList"
                    :display-text="this.getMobileNumberDisplayText"
                    :disabled="mobileNumberPrefixList.length == 1"
                    :custom-class="'mr-2 reg-mobile-prefix'"
                  ></app-form-field>
                </v-col>
                <v-col cols="7">
                  <app-form-field
                    :enabledLabel="false"
                    v-model.trim="registerObj.mobileNumber"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    :rules="validator.mobileNumberRules(defaultLanguage)"
                  ></app-form-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="space-between">
                <v-col cols="4" md="3">
                  <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                    {{ $t(`field.otpNo`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                </v-col>
                <v-col cols="8" md="9" class="text-right">
                  <v-btn
                    :v-show="smsRequestAvailable && smsVerificationSent"
                    text
                    height="auto"
                    @click="requestSmsVerificationCode"
                    class="text-capitalize pr-0 font-weight-bold title_color2--text"
                    :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2'"
                  >
                    {{ $t(`button.notReceivedOtp`) }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5" class="pr-2">
                  <v-btn
                    height="auto"
                    :disabled="smsVerificationSent && !smsVerificationResult"
                    @click="this.requestSmsVerificationCode"
                    color="black"
                    depressed
                    class="otp-button theme-button font-weight-bold yellow--text"
                  >
                    {{ $t(`button.requestOtp`) }}
                  </v-btn>
                </v-col>
                <v-col cols="7">
                  <app-form-field :enabledLabel="false" v-model.trim="registerObj.verificationCode" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()"></app-form-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="registerObj.referralCode"
                    :label="$t(`field.referralCode`)"
                    :placeholder="$t(`fieldHint.optional`)"
                    :rules="validator.referralCodeRules()"
                    :readonly="isReferralCodeReadonly"
                    :isOptional="true"
                  ></app-form-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-checkbox class="mt-0 pt-0">
                    <template v-slot:label>
                      <p class="caption pt-2 mb-0">
                        <label>{{ $t(`message.registerDisclaimer_1`) }}</label>
                        <label class="text-decoration-underline font-weight-bold" style="cursor:pointer" @click="openRegisterTncDialog">{{ $t(`message.registerDisclaimer_2`) }}</label>
                      </p>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <app-button class="dialog-button" :action="this.register" :title="$t(`button.register`)"></app-button>
                </v-col>
                <v-col cols="12" class="text-center">
                  <label v-show="registerErrorMsg" class="red--text subtitle-2">{{ registerErrorMsg }}</label>
                  <label v-show="errorMsg" class="red--text subtitle-2">{{ errorMsg }}</label>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-window-item>
        <!-- <v-window-item :value="2">
            <div class="pa-4 text-center">
                <v-img class="mb-4" contain height="128" src="https://cdn.vuetifyjs.com/images/logos/v.svg"></v-img>
                <h3 class="text-h6 font-weight-light mb-2">
                    Welcome to Vuetify
                </h3>
                <span class="text-caption grey--text">Thanks for signing up!</span>
            </div>
        </v-window-item> -->
        <v-row no-gutters>
          <v-col cols="12" class="mt-5">
            <v-item-group v-model="step" class="text-center" mandatory>
              <v-item v-for="n in regSteps" :key="`btn-${n}`">
                <v-btn
                  color="white"
                  class="ma-2 resgiter-dialog-pagination"
                  active-class="resgiter-dialog-pagination-active"
                  x-small
                  icon
                  :input-value="step == n - 1"
                  @click="onChangePagination(n - 1)"
                >
                  {{ n }}
                </v-btn>
              </v-item>
              <v-item v-slot="{ active, toggle }">
                <v-btn active-class="resgiter-dialog-pagination-active" icon :input-value="active" :disabled="true">
                  <v-icon>check_circle</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>
      </v-window>
      <!-- <p class="mt-5 text-center body-1">
              {{ $t(`message.alreadyHaveAccount`) }}
              <v-btn text height="auto" @click="openLogin" class="pa-0 text-uppercase font-weight-bold secondary--text">{{ $t(`label.signIn`) }}
              </v-btn>
          </p> -->
      <div v-if="step == 0">
        <v-row no-gutters class="py-6">
          <v-col cols="12">
            <v-divider class="dialog-divider"></v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
              {{ $t(`message.alreadyHaveAccount`) }}
            </label>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn height="auto" @click="openLogin" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
              {{ $t(`button.login`) }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <app-dialog :dialogShow="registerTncDialog.show" :max-width="880" :title="registerTncDialog.title" :closeAction="this.closeRegisterTncDialog">
      <v-container class="fill-height px-6">
        <iframe class="registerTnc-iframe" :src="registerTncUrl" frameborder="0"></iframe>
      </v-container>
      <!-- <app-button :action="this.closeRegisterTncDialog" :title="$t(`label.agree`)"></app-button> -->
    </app-dialog>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import { formValidator, uiHelper, locale } from '@/util'
import { MEMBER_REGISTER, MEMBER_SMS_REQUEST, MEMBER_SMS_VERIFY, MEMBER_GET_SMS_REQUIREMENT, MEMBER_CHECK_USERNAME, MEMBER_RESET_CHECK_USERNAME } from '@/store/member.module'
import { SESSION, SHARED } from '@/constants/constants'
import { errorCodeHelper } from '@/util/error-code-helper'
import { MEMBER_RESET_SMS_VERIFICATION } from '@/store/member.module'

export default {
  name: 'appRegister',
  mounted() {
    this.checkAffiliateReferralCode()
    this.checkPhoneVerificationRequirements()
  },
  props: {
    openLoginDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    registerErrorMsg: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: () => ({
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    registerTncUrl: '',
    registerTncDialog: {
      show: false,
      title: locale.getMessage(`label.registerAccountAgreement`)
    },
    validator: formValidator,
    stringFormat: stringFormat,
    isReferralCodeReadonly: false,
    registerObj: {
      memberCode: '',
      password: '',
      currency: uiHelper.getCurrency(),
      email: '',
      mobileNumber: '',
      verificationCode: '',
      line: '',
      referralCode: '',
      isEarlyBird: '0',
      domain: '',
      language: '',
      platform: ''
    },
    confirmedPassword: '',
    step: 0,
    regSteps: 2,
    mobileNumberPrefixList: [
      // {
      //     text: "+91",
      //     value: "INR"
      // },
      {
        text: '+880',
        value: 'BDT'
      }
    ],
    currencyList: [
      // 'INR',
      'BDT'
    ],
    errorMsg: undefined,
    userNameErrorMsg: undefined
  }),
  computed: {
    smsRequired() {
      return this.$store.state.member.smsVerificationRequired.required
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.success
    },
    smsVerificationError() {
      return this.$store.state.member.smsVerificationResult.message
    },
    smsRequestError() {
      return this.$store.state.member.smsVerificationSent.message
    },
    smsRequestAvailable() {
      return this.$store.state.member.smsVerificationSent.countDownTimer <= 0
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer
    },
    checkUsernameResult() {
      return this.$store.state.member.checkUsernameResult.completed
    }
  },
  watch: {
    smsRequestError() {
      if (this.$store.state.member.smsVerificationSent.complete && !this.$store.state.member.smsVerificationSent.success) {
        this.errorMsg = errorCodeHelper.getErrorCodeDesc(this.$store.state.member.smsVerificationSent.code)
      }
    },
    checkUsernameResult() {
      if (this.$store.state.member.checkUsernameResult.completed) {
        if (this.$store.state.member.checkUsernameResult.success) {
          this.userNameErrorMsg = undefined
        } else {
          this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameUsed`)
        }
        this.$store.dispatch(`${MEMBER_RESET_CHECK_USERNAME}`)
      }
    }
  },
  methods: {
    checkUsername() {
      if (this.registerObj.memberCode.trim() !== '') {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_CHECK_USERNAME}`, {
          registerObj
        })
      }
    },
    onChangePagination(page) {
      if (page == 1) {
        if (this.$refs.registerForm1.validate()) {
          this.step = page
        }
      } else {
        this.step = page
      }
    },
    getMobileNumberDisplayText(data) {
      return data.text
    },
    errorDialog(errorCode) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.register`)
      dialog.dialogXButton = null
      dialog.message.push(errorCodeHelper.getErrorCodeDesc(errorCode))
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    checkPhoneVerificationRequirements() {
      this.$store.dispatch(`${MEMBER_GET_SMS_REQUIREMENT}`)
    },
    openRegisterTncDialog() {
      this.registerTncUrl = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/register-tnc.html`
      this.registerTncDialog.show = true
      this.registerTncDialog.dialogXButton = this.closePromotionTncDialog
    },
    closeRegisterTncDialog() {
      this.registerTncDialog.show = false
    },
    openLogin() {
      this.$refs.registerForm1.reset()
      if (this.$refs.registerForm2) this.$refs.registerForm2.reset()
      this.openLoginDialog()
    },
    register() {
      // if (this.smsRequired && !this.smsVerificationSent && !this.smsVerificationResult.success) {
      //     this.requestSmsVerificationCode()
      //     return
      // }
      if (this.$refs.registerForm2.validate()) {
        //this.registerObj.currency = uiHelper.getCurrency()
        this.registerObj.platform = uiHelper.getPlatform()
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_REGISTER}`, {
          registerObj
        })
        this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      if (this.registerObj.mobileNumber) {
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
    },
    async verifySmsCode() {
      if (this.$refs.registerForm.validate()) {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    checkAffiliateReferralCode() {
      let affilaiteCode = uiHelper.getCookie(SESSION.REFER_CODE)
      if (affilaiteCode != null && affilaiteCode != '') {
        this.registerObj.referralCode = affilaiteCode
        this.isReferralCodeReadonly = true
      } else {
        let affiliateDomain = SHARED.AFFILIATE_DOMAIN.find(x => x.domain.toLowerCase() == window.location.host.toLowerCase())
        if (affiliateDomain != null && affiliateDomain != undefined) {
          this.registerObj.referralCode = affiliateDomain.affiliateCode
          this.isReferralCodeReadonly = true
        } else {
          this.registerObj.referralCode = ''
          this.isReferralCodeReadonly = false
        }
      }
    },
    nextPage() {
      if (this.$refs.registerForm1.validate() && !this.userNameErrorMsg) this.step++
    }
  }
}
</script>

<style lang="scss">
.otp-button {
  color: black;
  border-radius: 10px;
  width: 100%;
  font-size: 14px !important;
  padding: 9px !important;
  text-transform: capitalize;
}

.registerTnc-iframe {
  width: 100%;
  height: 500px;
}

.sms-confirm-button.col {
  padding-left: 1em;

  button {
    margin-top: 0 !important;
    height: 50px !important;
  }
}

.resgiter-dialog-pagination {
  background-color: #acacac;
  color: #ffffff !important;
}

.resgiter-dialog-pagination-active {
  background-color: #fddd04;
}

.resgiter-dialog-pagination.theme--light.v-btn.v-btn--disabled {
  color: #ffffff !important;
}

@media (max-width: 959px) {
  .otp-button {
    //width: 102 !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 10px !important;
  }
}

@media (max-width: 599px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 11px !important;
  }
}

@media (max-width: 425px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 7px !important;
  }
}

@media (max-width: 375px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 11px !important;
    padding: 4px !important;
  }
  .reg-mobile-prefix.v-input {
    font-size: 12px !important;

    .v-select__selection--comma {
      margin: 7px 0px 7px 0px !important;
    }

    .v-input__icon {
      height: 14px !important;
      width: 14px !important;
      min-width: 14px !important;
    }
  }
}
</style>
