import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from 'vuex-persistedstate'
import shared from './shared.module'
import user from './user.module'
import member from './member.module'
import announcement from './announcement.module'
import bonus from './bonus.module'
import game from './game.module'
import payment from './payment.module'
import transfer from './transfer.module'
import lookup from './lookup.module'
import affiliate from './affiliate.module'
import affiliatePayment from './affiliate.payment.module'
import cms from './cms.module'
import referral from './referral.module'
import wheel from './wheel.module'
import inbox from './inbox.module'
import reward from './reward.module'
import history from './history.module'
import affiliateBonus from './affiliate.bonus.module'

//import Cookies from "js-cookie";

Vue.use(Vuex)

export default new Vuex.Store({
  /*plugins: [
    createPersistedState({
      paths: ["game", "lookup"],
    })
  ],*/
  modules: {
    cms,
    shared,
    user,
    member,
    announcement,
    bonus,
    game,
    screen,
    payment,
    transfer,
    lookup,
    affiliate,
    affiliatePayment,
    referral,
    wheel,
    inbox,
    reward,
    history,
    affiliateBonus
  }
})
