import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

/* SERVICE EXPORT FUNCTION NAME */
export const historyService = {
  getHistoryOverview,
  getHistoryDetail
}

/* SERVICE URL PATH */
const HISTORY = {
  HISTORY: 'player-bet-history',
  OVERVIEW: 'overview'
}

function getHistoryOverview(data) {
  const requestOptions = {
    params: {
      page: data.page,
      size: data.itemsPerPage,
    }
  }
  return apiHelper().get(`/${HISTORY.HISTORY}/${HISTORY.OVERVIEW}`, requestOptions)
}

function getHistoryDetail(data) {
  const requestOptions = {
    params: {
      start_date: data.startDate,
      end_date: data.endDate,
      provider: data.provider,
      game_type: data.gameType,
      page: data.page,
      size: data.itemsPerPage
    }
  }
  return apiHelper().get(`/${HISTORY.HISTORY}`, requestOptions)
}