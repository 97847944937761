/* e.g. import { authService } from '@/services' */
export * from './user.service'
export * from './game.service'
export * from './member.service'
export * from './announcement.service'
export * from './bonus.service'
export * from './payment.service'
export * from './transfer.service'
export * from './lookup.service'
export * from './affiliate.service'
export * from './affiliate.payment.service'
export * from './cms.service'
export * from './referral.service'
export * from './wheel.service'
export * from './inbox.service'
export * from './reward.service'
export * from './history.service'