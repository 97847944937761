<template>
  <v-row no-gutters>
    <v-col cols="12" class="px-8">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="text-center mb-0"><img src="/static/image/register/register_success.png" width="50" height="50"
                                           alt="JAYA9 cricket exchange" /></p>
          <p class="text-center font-weight-bold">{{ $t("label.registerSuccessText") }}</p>
          <p class="mb-0">{{ $t("label.registerSuccessMessage") }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="mt-5">
          <v-item-group class="text-center" mandatory>
            <v-item v-for="n in 2" :key="`btn-${n}`" v-slot="{ active, toggle }">
              <v-btn color="white" class="ma-2 resgiter-dialog-pagination" x-small icon :input-value="active"
                     @click="toggle">
                {{ n }}
              </v-btn>
            </v-item>
            <v-item>
              <v-icon dense class="ml-1 resgiter-dialog-pagination-active">done</v-icon>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-6">
        <v-col cols="12">
          <v-divider class="dialog-divider"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <p class="caption pt-2">
            <label>{{ $t(`label.registerSuccessNote`) }}</label>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <app-button class="full-width white--text dialog-button" :title="$t(`label.depositNow`)"
                      :action="this.goToDepositPage"></app-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  ROUTE_NAME
} from "@/constants/route.constants";
import { uiHelper } from "@/util";
import { SESSION } from "@/constants/constants";

export default {
  name: "registerSuccess",
  props: {
    closeRegisterSuccessDialog: {
      type: Function,
      required: true,
      default: () => {
      }
    }
  },
  mounted(){
    // this.generateRegSuccessPixelAtBody();
  },
  created() {
    this.generateRegSuccessPixel();
    let afCode = uiHelper.getCookie(SESSION.AFF_CODE);
    if (uiHelper.getCookie(SESSION.UTM_S2) != null) {
      this.firePixel();
    }
    if (afCode != null && afCode != "") {
      this.fireJayaInsine();
    }
  },
  methods: {
    generateRegSuccessPixel() {
      var scripts = [
        "/static/js/reg_success_pixel.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    generateRegSuccessPixelAtBody(){
      var scripts = [
        "/static/js/jayaRegSucceedDepoPixel.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.body.appendChild(tag);
      });
    },
    fireJayaInsine() {
      try {
        let url = "https://insinestourroll.com/postback?cid=" + uiHelper.getCookie(SESSION.AFF_CODE) + "&payout=OPTIONAL&currency=OPTIONAL&txid=OPTIONAL";
        $.get(url, function(data) {
          console.log(data);
        });
      } catch (err) {
        console.log(err);
      }
    },
    firePixel() {
      try {
        let url = "https://e5xbo.rdtk.io/postback?clickid=" + uiHelper.getCookie(SESSION.UTM_S2) + "&sum=1";
        $.get(url, function(data) {
          console.log(data);
        });
      } catch (err) {
        console.log(err);
      }
    },
    goToDepositPage() {
      this.$router.push({
        name: ROUTE_NAME.FUNDS
      });
      this.closeRegisterSuccessDialog();
    }
  }
};
</script>

<style lang="scss">
.resgiter-dialog-pagination {
  background-color: #ACACAC;
  color: #ffffff !important;
}

.resgiter-dialog-pagination-active {
  background-color: #14805e;
  border-radius: 30px;
  color: white !important;
}

.resgiter-dialog-pagination.theme--light.v-btn.v-btn--disabled {
  color: #ffffff !important;

}
</style>
