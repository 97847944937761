import { DevicePlatform } from '@/constants/enums'
import { SESSION, SHARED, CACHE_KEY } from '@/constants/constants'
import { locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'

export const uiHelper = {
  /* Page */
  getPlatform,
  getHostname,
  getHost,
  IsIos,
  /* Currency */
  getCurrency,
  setCurrency,
  /* language */
  getLanguage,
  setLanguage,
  /* Cookie */
  getCookie,
  setCookie,
  removeCookie,
  getPopup,
  /* Local Storage With Expiry */
  getLocalStorage,
  setLocalStorage,
  /* Dialog Obj Initialize */
  initializePageDialogProperty,

  isAuthenticated,
  openPopup,
  openNewTab,
  getFingerprints,
  downloadAPK,
  isAndroid,
  setMetaTag,
  setTitle,
  setCanonical,
  getFooterSEO,
  getFooterSEOVip,
  getCidFromUrl
}

var MD5 = function(d) {
  var r = M(V(Y(X(d), 8 * d.length)))
  return r.toLowerCase()
}

function M(d) {
  for (var _, m = '0123456789ABCDEF', f = '', r = 0; r < d.length; r++) (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _))
  return f
}

function X(d) {
  for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0
  for (m = 0; m < 8 * d.length; m += 8) _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32
  return _
}

function V(d) {
  for (var _ = '', m = 0; m < 32 * d.length; m += 8) _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255)
  return _
}

function Y(d, _) {
  ;(d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _)
  for (var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0; n < d.length; n += 16) {
    var h = m,
      t = f,
      g = r,
      e = i
    ;(f = md5_ii(
      (f = md5_ii(
        (f = md5_ii(
          (f = md5_ii(
            (f = md5_hh(
              (f = md5_hh(
                (f = md5_hh(
                  (f = md5_hh(
                    (f = md5_gg(
                      (f = md5_gg(
                        (f = md5_gg(
                          (f = md5_gg(
                            (f = md5_ff(
                              (f = md5_ff(
                                (f = md5_ff(
                                  (f = md5_ff(
                                    f,
                                    (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 0], 7, -680876936)), f, r, d[n + 1], 12, -389564586)), m, f, d[n + 2], 17, 606105819)),
                                    i,
                                    m,
                                    d[n + 3],
                                    22,
                                    -1044525330
                                  )),
                                  (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 4], 7, -176418897)), f, r, d[n + 5], 12, 1200080426)), m, f, d[n + 6], 17, -1473231341)),
                                  i,
                                  m,
                                  d[n + 7],
                                  22,
                                  -45705983
                                )),
                                (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 8], 7, 1770035416)), f, r, d[n + 9], 12, -1958414417)), m, f, d[n + 10], 17, -42063)),
                                i,
                                m,
                                d[n + 11],
                                22,
                                -1990404162
                              )),
                              (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 12], 7, 1804603682)), f, r, d[n + 13], 12, -40341101)), m, f, d[n + 14], 17, -1502002290)),
                              i,
                              m,
                              d[n + 15],
                              22,
                              1236535329
                            )),
                            (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 1], 5, -165796510)), f, r, d[n + 6], 9, -1069501632)), m, f, d[n + 11], 14, 643717713)),
                            i,
                            m,
                            d[n + 0],
                            20,
                            -373897302
                          )),
                          (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 5], 5, -701558691)), f, r, d[n + 10], 9, 38016083)), m, f, d[n + 15], 14, -660478335)),
                          i,
                          m,
                          d[n + 4],
                          20,
                          -405537848
                        )),
                        (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 9], 5, 568446438)), f, r, d[n + 14], 9, -1019803690)), m, f, d[n + 3], 14, -187363961)),
                        i,
                        m,
                        d[n + 8],
                        20,
                        1163531501
                      )),
                      (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 13], 5, -1444681467)), f, r, d[n + 2], 9, -51403784)), m, f, d[n + 7], 14, 1735328473)),
                      i,
                      m,
                      d[n + 12],
                      20,
                      -1926607734
                    )),
                    (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)), f, r, d[n + 8], 11, -2022574463)), m, f, d[n + 11], 16, 1839030562)),
                    i,
                    m,
                    d[n + 14],
                    23,
                    -35309556
                  )),
                  (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)), f, r, d[n + 4], 11, 1272893353)), m, f, d[n + 7], 16, -155497632)),
                  i,
                  m,
                  d[n + 10],
                  23,
                  -1094730640
                )),
                (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)), f, r, d[n + 0], 11, -358537222)), m, f, d[n + 3], 16, -722521979)),
                i,
                m,
                d[n + 6],
                23,
                76029189
              )),
              (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)), f, r, d[n + 12], 11, -421815835)), m, f, d[n + 15], 16, 530742520)),
              i,
              m,
              d[n + 2],
              23,
              -995338651
            )),
            (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)), f, r, d[n + 7], 10, 1126891415)), m, f, d[n + 14], 15, -1416354905)),
            i,
            m,
            d[n + 5],
            21,
            -57434055
          )),
          (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)), f, r, d[n + 3], 10, -1894986606)), m, f, d[n + 10], 15, -1051523)),
          i,
          m,
          d[n + 1],
          21,
          -2054922799
        )),
        (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)), f, r, d[n + 15], 10, -30611744)), m, f, d[n + 6], 15, -1560198380)),
        i,
        m,
        d[n + 13],
        21,
        1309151649
      )),
      (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)), f, r, d[n + 11], 10, -1120210379)), m, f, d[n + 2], 15, 718787259)),
      i,
      m,
      d[n + 9],
      21,
      -343485551
    )),
      (m = safe_add(m, h)),
      (f = safe_add(f, t)),
      (r = safe_add(r, g)),
      (i = safe_add(i, e))
  }
  return Array(m, f, r, i)
}

function md5_cmn(d, _, m, f, r, i) {
  return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m)
}

function md5_ff(d, _, m, f, r, i, n) {
  return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n)
}

function md5_gg(d, _, m, f, r, i, n) {
  return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n)
}

function md5_hh(d, _, m, f, r, i, n) {
  return md5_cmn(_ ^ m ^ f, d, _, r, i, n)
}

function md5_ii(d, _, m, f, r, i, n) {
  return md5_cmn(m ^ (_ | ~f), d, _, r, i, n)
}

function safe_add(d, _) {
  var m = (65535 & d) + (65535 & _)
  return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m)
}

function bit_rol(d, _) {
  return (d << _) | (d >>> (32 - _))
}

function getFingerprints() {
  var timestamp = new Date().getTime()
  var navigator_info = window.navigator
  var screen_info = window.screen
  var uid = navigator_info.mimeTypes.length
  uid += navigator_info.userAgent.replace(/\D+/g, '')
  uid += navigator_info.plugins.length
  uid += screen_info.height || ''
  uid += screen_info.width || ''
  uid += screen_info.pixelDepth || ''
  uid += timestamp
  var result = MD5(uid)
  console.log(result)
  return result
}

function getPopup() {
  return DevicePlatform.POPUP
}

function initializePageDialogProperty() {
  let initPageDialog = {
    title: locale.getMessage(`label.system`),
    dialogXButton: this.closePageDialog,
    messageTitle: '',
    message: [],
    button: []
  }
  return initPageDialog
}

function getPlatform() {
  let type = DevicePlatform.DESKTOP
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/is) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    type = DevicePlatform.MOBILE
  } else {
    type = DevicePlatform.DESKTOP
  }
  return type
}

function IsIos() {
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/is) || navigator.userAgent.match(/iPod/i)) {
    return true
  }
}

function getHostname() {
  return window.location.origin
}

function getHost() {
  return window.location.host
}

function setCookie(name, value, day = 100) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() + day))
  let cookie = escape(name) + '=' + escape(value) + ';expires=' + expiration + '; path=/'
  document.cookie = cookie
}

function getCookie(name) {
  let key = name + '='
  let cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length)
    }
  }
  return null
}

function removeCookie(name) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() - 1))
  let cookie = escape(name) + '=' + ';expires=' + expiration + ';'
  document.cookie = cookie
}

function getCurrency() {
  var currencySession = localStorage.getItem(SESSION.CURRENCY)
  if (currencySession == '' || currencySession == null) {
    setCurrency(SHARED.DEFAULT_CURRENCY)
  }
  return localStorage.getItem(SESSION.CURRENCY)
}

function setCurrency(value) {
  localStorage.setItem(SESSION.CURRENCY, value)
}

function getLanguage() {
  var languageCookie = getCookie(SESSION.LANGUAGE)
  if (languageCookie == '' || languageCookie == null) {
    setCookie(SESSION.LANGUAGE, SHARED.DEFAULT_LANGUAGE)
    languageCookie = SHARED.DEFAULT_LANGUAGE
  }
  return languageCookie
}

function setLanguage(value) {
  localStorage.setItem(SESSION.LANGUAGE, value)
}

function isAuthenticated() {
  let isLoggedIn = localStorage.getItem(SESSION.TOKEN)
  let result = true

  if (!isLoggedIn) {
    //check if user has logged in to the system
    result = false
  }

  return result
}

function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

function setLocalStorage(key, value, ttl) {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 60000
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function openPopup(url, title, popupWidth, popupHeight) {
  let isAndroidMobile = localStorage.getItem(CACHE_KEY.IS_ANDROID)
  if (isAndroidMobile != null) {
    isAndroidMobile = JSON.parse(isAndroidMobile)
  }
  if (isAndroidMobile != null && isAndroidMobile.value) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft
    const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(
      '',
      title,
      `
            width=${popupWidth}, 
            height=${popupHeight}, 
            top=${top}, 
            left=${left}
            `
    )

    newWindow.document.write('Redirecting...')
    newWindow.location.href = url
  } else {
    window.open(url, '_blank')
  }

  // if (window.hasOwnProperty('focus')) newWindow.focus()
}

function downloadAPK() {
  // window.open(SHARED.MEDIA_DOMAIN + '/jaya/jaya9v1_2.apk?v=1')
  window.open('/jaya9_2024_new.apk');
}

function isAndroid() {
  return /(android)/i.test(navigator.userAgent)
}

function openNewTab(url, title, popupWidth, popupHeight) {
  setTimeout(() => {
    window.open(url, '_blank')
  })
}

function setMetaTag(property, content) {
  try {
    var selector = document.querySelector('meta[name="' + property + '"]')
    selector.setAttribute('content', content)
  } catch (ex) {
    /*attr not found*/
  }
}

function setTitle(title) {
  document.title = title
}

function setCanonical(link) {
  let canonical = document.querySelector('link[rel="canonical"]')
  if (canonical) {
    document.querySelector('link[rel="canonical"]').setAttribute('href', link)
  } else {
    var linkDocument = document.createElement('link')
    linkDocument.href = link
    linkDocument.rel = 'canonical'
    document.getElementsByTagName('head')[0].appendChild(linkDocument)
  }
}

function getFooterSEO(pageObj) {
  let currentLanguage = this.getLanguage()
  let footerSEO = {
    title: '',
    desc_1: '',
    desc_2: '',
    desc_3: ''
  }

  if (currentLanguage == 'bd') {
    let currentRoute = pageObj.currentRoute
    console.log('lookupmodule.js current route : ' + currentRoute)

    if (currentRoute == ROUTE_NAME.HOME) {
      footerSEO.title = locale.getMessage('footer.homeFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.homeFooterSEO_Desc_1')
      footerSEO.desc_2 = locale.getMessage('footer.homeFooterSEO_Desc_2')
    } else if (
      currentRoute == ROUTE_NAME.SLOT ||
      currentRoute == ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME ||
      currentRoute == ROUTE_NAME.SLOT_BY_GAMENAME ||
      currentRoute == ROUTE_NAME.SLOT_BY_PROVIDER
    ) {
      footerSEO.title = locale.getMessage('footer.slotFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.slotFooterSEO_1')
      footerSEO.desc_2 = locale.getMessage('footer.slotFooterSEO_2')
    } else if (currentRoute == ROUTE_NAME.CASINO || currentRoute == ROUTE_NAME.CASINO_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.casinoFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.casinoFooterSEO_2')
      footerSEO.desc_2 = locale.getMessage('footer.casinoFooterSEO_3')
    } else if (currentRoute == ROUTE_NAME.TABLE_GAMES || currentRoute == ROUTE_NAME.TABLE_GAMES_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.tablegamesFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.tablegamesFooterSEO_1')
      footerSEO.desc_2 = locale.getMessage('footer.tablegamesFooterSEO_2')
    } else if (currentRoute == ROUTE_NAME.SPORTSBOOK || currentRoute == ROUTE_NAME.SPORTSBOOK_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.sportsbookFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.sportsbookFooterSEO_1')
      footerSEO.desc_2 = locale.getMessage('footer.sportsbookFooterSEO_2')
    } else if (currentRoute == ROUTE_NAME.PROMOTION || currentRoute == ROUTE_NAME.PROMOTION_IN_PROGRESS || currentRoute == ROUTE_NAME.PROMOTION_HISTORY) {
      footerSEO.title = locale.getMessage('footer.promotionFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.promotionFooterSEO_1')
      footerSEO.desc_2 = locale.getMessage('footer.promotionFooterSEO_2')
    } else if (currentRoute == ROUTE_NAME.LOTTERY || currentRoute == ROUTE_NAME.LOTTERY_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.lotteryFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.lotteryFooterSEO_1')
      footerSEO.desc_2 = locale.getMessage('footer.lotteryFooterSEO_2')
    } else if (currentRoute == ROUTE_NAME.CRICKET || currentRoute == ROUTE_NAME.CRICKET_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.cricketFooterSEO_Title')
      footerSEO.desc_1 = locale.getMessage('footer.cricketFooterSEO_2')
      footerSEO.desc_2 = locale.getMessage('footer.cricketFooterSEO_3')
    } else {
      //default footer texts
      footerSEO.title = locale.getMessage('footer.trustedPremiumOnlineCasinoTitle')
      footerSEO.desc_1 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_1')
      footerSEO.desc_2 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_2')
    }
  } else {
    /*if en use back prev default SEO TEXT*/
    footerSEO.title = locale.getMessage('footer.trustedPremiumOnlineCasinoTitle')
    footerSEO.desc_1 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_1')
    footerSEO.desc_2 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_2')
  }

  return footerSEO
}
function getFooterSEOVip(pageObj) {
  let currentLanguage = this.getLanguage()
  let footerSEO = {
    title: '',
    desc_1: '',
    desc_2: '',
    desc_3: ''
  }

  if (currentLanguage == 'bd') {
    let currentRoute = pageObj.currentRoute
    console.log('lookupmodule.js current route : ' + currentRoute)

    if (currentRoute == ROUTE_NAME.HOME) {
      footerSEO.title = locale.getMessage(`footer.homeFooterSEO_vip_Title`, [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.homeFooterSEO_vip_Desc_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.homeFooterSEO_vip_Desc_2', [window.location.hostname])
      console.log((window.location.hostname))
    } else if (
      currentRoute == ROUTE_NAME.SLOT ||
      currentRoute == ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME ||
      currentRoute == ROUTE_NAME.SLOT_BY_GAMENAME ||
      currentRoute == ROUTE_NAME.SLOT_BY_PROVIDER
    ) {
      footerSEO.title = locale.getMessage('footer.slotFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.slotFooterSEO_vip_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.slotFooterSEO_vip_2', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.CASINO || currentRoute == ROUTE_NAME.CASINO_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.casinoFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.casinoFooterSEO_vip_2', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.casinoFooterSEO_vip_3', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.TABLE_GAMES || currentRoute == ROUTE_NAME.TABLE_GAMES_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.tablegamesFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.tablegamesFooterSEO_vip_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.tablegamesFooterSEO_vip_2', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.SPORTSBOOK || currentRoute == ROUTE_NAME.SPORTSBOOK_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.sportsbookFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.sportsbookFooterSEO_vip_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.sportsbookFooterSEO_vip_2', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.PROMOTION || currentRoute == ROUTE_NAME.PROMOTION_IN_PROGRESS || currentRoute == ROUTE_NAME.PROMOTION_HISTORY) {
      footerSEO.title = locale.getMessage('footer.promotionFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.promotionFooterSEO_vip_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.promotionFooterSEO_vip_2', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.LOTTERY || currentRoute == ROUTE_NAME.LOTTERY_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.lotteryFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.lotteryFooterSEO_vip_1', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.lotteryFooterSEO_vip_2', [window.location.hostname])
    } else if (currentRoute == ROUTE_NAME.CRICKET || currentRoute == ROUTE_NAME.CRICKET_BY_PROVIDER) {
      footerSEO.title = locale.getMessage('footer.cricketFooterSEO_vip_Title', [window.location.hostname])
      footerSEO.desc_1 = locale.getMessage('footer.cricketFooterSEO_vip_2', [window.location.hostname])
      footerSEO.desc_2 = locale.getMessage('footer.cricketFooterSEO_vip_3', [window.location.hostname])
    } else {
      //default footer texts
      footerSEO.title = locale.getMessage('footer.trustedPremiumOnlineCasinoTitle')
      footerSEO.desc_1 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_1')
      footerSEO.desc_2 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_2')
    }
  } else {
    /*if en use back prev default SEO TEXT*/
    footerSEO.title = locale.getMessage('footer.trustedPremiumOnlineCasinoTitle')
    footerSEO.desc_1 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_1')
    footerSEO.desc_2 = locale.getMessage('footer.trustedPremiumOnlineCasinoDesc_2')
  }

  return footerSEO
}
function getCidFromUrl() {
  let url = window.location.href
  let cid = ''
  if (url.indexOf('cid=') > -1) {
    cid = url.split('cid=')[1].split('&')[0]
  }
  if (cid) {
    sessionStorage.setItem(SESSION.CID, cid)
  }
  return cid
}
