import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

export const affiliateService = {
  getDetails,
  getReferralLink,
  getProductReport,
  getProductDetailReport,
  upgradeAffiliateType,
  getMemberReport,
  getMemberDetailReport,
  getQuickLink,
  getAgentReport,
  getReferralCode,
  getAffiliateTypeLog,
  getAffiliateTransferHistory,
  getAgentProductDownlineReport,
  getAffiliateDownlineListing,
  getAffiliateDownlineDetail,
  getAffiliateUplineReferral,
  getAffiliateBonusDetail,
}

//API URLs
const AFFILIATE = {
  AFFILIATE: 'affiliate',
  QUICKLINK: 'quicklink',
  DETAILS: 'details',
  REPORTS: 'reports',
  PRODUCTS: 'products',
  TYPE: 'type',
  LIST: 'list',
  AGENTS: 'agents',
  CODE: 'code',
  LOG: 'log',
  HISTORY: 'histories',
  DOWNLINE: 'downline',
  UPLINE: 'upline',
  REFERRAL: 'referral',
  BONUS_DETAIL: 'bonus/detail',
  ONETIME: 'one-time/bonus'
}

function getDetails() {
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.DETAILS}`)
}

function getReferralLink(filter) {
  const requestOptions = {
    params: {
      mode: filter.mode,
      language: filter.link
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.QUICKLINK}`, requestOptions)
}

function getProductReport(filter) {
  const requestOptions = {
    params: {
      mode: filter.mode,
      report_start_date: filter.startDate,
      report_end_date: filter.endDate
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.REPORTS}/${AFFILIATE.PRODUCTS}`, requestOptions)
}
function getProductDetailReport(filter) {
  const requestOptions = {
    params: {
      mode: filter.mode,
      sort: 'report_date DESC',
      report_start_date: filter.startDate,
      report_end_date: filter.endDate,
      product_type: filter.productType
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.REPORTS}/${AFFILIATE.PRODUCTS}`, requestOptions)
}

function upgradeAffiliateType() {
  return apiHelper().patch(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.TYPE}`)
}

function getMemberReport(filter) {
  const requestOptions = {
    params: {
      size: filter.size,
      page: filter.page,
      is_member_active: filter.status
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.LIST}`, requestOptions)
}

function getMemberDetailReport(filter) {
  const requestOptions = {
    params: {
      mode: filter.mode,
      size: filter.size,
      page: filter.page,
      sort: 'report_date DESC',
      report_start_date: filter.startDate,
      report_end_date: filter.endDate
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.REPORTS}`, requestOptions)
}

function getAgentReport(filter) {
  const requestOptions = {
    params: {
      mode: filter.mode,
      size: filter.size,
      page: filter.page,
      affiliate_type: filter.affiliateType,
      sort: 'report_date DESC',
      report_start_date: filter.startDate,
      report_end_date: filter.endDate
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.REPORTS}/${AFFILIATE.AGENTS}`, requestOptions)
}

function getQuickLink(filter) {
  const requestOptions = {
    domain: filter.domain
  }
  return apiHelper().post(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.QUICKLINK}`, requestOptions, {
    params: {
      mode: filter.mode,
      language: filter.language
    }
  })
}

function getReferralCode(filter) {
  const requestOptions = {
    params: {
      name: filter.name
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.CODE}`, requestOptions)
}

function getAffiliateTypeLog() {
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.TYPE}/${AFFILIATE.LOG}`)
}

function getAffiliateTransferHistory(obj) {
  let requestOptions = {
    params: {
      page: obj.page,
      size: obj.itemsPerPage,
      status: obj.status,
      type: obj.type,
      from_wallet: obj.fromWallet,
      to_wallet: obj.toWallet,
      start_time: obj.fromDate,
      end_time: obj.toDate
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.HISTORY}`, requestOptions)
}

function getAgentProductDownlineReport(obj) {
  let requestOptions = {
    params: {
      page: obj.page,
      size: obj.size,
      mode: obj.mode,
      membercode: obj.memberCode,
      report_start_date: obj.startDate,
      report_end_date: obj.endDate
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.REPORTS}/${AFFILIATE.PRODUCTS}/${AFFILIATE.DOWNLINE}`, requestOptions)
}

function getAffiliateDownlineListing(filter) {
  let requestOptions = {
    params: {
      page: filter.page,
      size: filter.size,
      start_time: filter.start_time,
      end_time: filter.end_time
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.DOWNLINE}/${AFFILIATE.LIST}`, requestOptions)
}

function getAffiliateDownlineDetail() {
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.DOWNLINE}`)
}

function getAffiliateUplineReferral() {
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.UPLINE}/${AFFILIATE.REFERRAL}`)
}

function getAffiliateBonusDetail() {
  const requestBody = {
    params: {
      currency: SHARED.DEFAULT_CURRENCY
    }
  }
  return apiHelper().get(`/${AFFILIATE.AFFILIATE}/${AFFILIATE.BONUS_DETAIL}`, requestBody)
}
