var SlotsProvider = {
    KISS918: "KISS918",
    MGS: "MGS",
    KISSKAYA: "KISSKAYA",
    PUSSY888: "PUSSY888",
    MEGA888: "MEGA888",
    XE88: "XE88",
    PT: "PT",
    GPI: "GPI",
    PNG: "PNG",
    PP: "PP",
    SPG: "SPG",
    JOKER: "JOKER",
    ONE: "ONE",
    IBC: "IBC",
    SUPERSPADE: "SUPERSPADE",
    SPRIBE: "SPRIBE",
    RT: "RT",
    YGG: "YGG",
    BETSOFT: "BETSOFT",
    INDISLOTS: "INDISLOTS",
    PLAYSON: "PLAYSON",
    HABA:"HABANERO",
    JILI:"JILI",
    JDB:"JDB"
}

var SportsProvider = {
    SBO: "SBO",
    WBET: "WBET",
    BTI: "BTI",
    IBC: "IBC"
}

var LiveProvider = {
    PT: "PT",
    GPI: "GPI",
    AB: "AB",
    SAG: "SAG",
    SEXY_V2: "SEXY_V2",
    PP: "PP",
    EVO: "EVO",
    DG: "DG",
    SUPERSPADE: "SUPERSPADE",
    SPRIBE: "SPRIBE",
    OT: "OT",
    JILI:"JILI"
}

var TableProvider = {
    SPRIBE: "SPRIBE",
    TAJ: "TAJ",
    JILI:"JILI",
    SEXY_V2: "SEXY_V2",
    KING_MAKER:"KINGMAKER"
}

var CricketProvider = {
    BETSWIZ: "BETSWIZ",
    SAP:"SAP"
}

export {SlotsProvider, SportsProvider, CricketProvider, LiveProvider, TableProvider}
