<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'forgot_password_panel px-3' :'forgot_password_panel px-12 py-8'">
      <v-snackbar class="pa-0" style="z-index:1000 !important;top:18%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
        <v-alert
          v-model="alertShow"
          dismissible
          :icon="alertSuccess ? 'check_circle' : 'mdi-close-circle-outline'"
          class="mb-0 pa-1"
          :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
          light
          close-icon="close"
        >
          {{ alertMessage }}
        </v-alert>
      </v-snackbar>
      <v-form ref="forgotPasswordForm">
<!--        <app-form-field-->
<!--            :enabledLabel=true-->
<!--            v-model.trim="forgotPasswordObj.memberCode"-->
<!--            :label="$t(`field.username`)"-->
<!--            :placeholder="$t(`field.username`).toLowerCase()"-->
<!--            :rules="validator.usernameRules()"-->
<!--            prependInnerIcon="person_outline">-->
<!--        </app-form-field>-->
<!--        -->
        <captcha
            ref="captchaCode"
            v-model.trim="captcha"
            :isOptional="true"
            :errorMsg="captchaErrorMsg"
            :rules="validator.captchaRules()"
        ></captcha>
        <v-row no-gutters>
          <v-col cols="12">
            <label class="input-field-label ma-0 pb-2 d-block">
                {{ $t(`field.phone`) }}
            </label>
            <v-row no-gutters>
              <v-col cols=4>
                <app-form-field 
                    v-model.trim="forgotPasswordObj.prefix"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="mobileNumberPrefixList"
                    :disabled="mobileNumberPrefixList.length == 1"
                    :display-text="this.getMobileNumberDisplayText"
                    :custom-class="'mr-1 reg-mobile-prefix'">
                </app-form-field>
              </v-col>
              <v-col cols=8>
                <app-form-field
                    :enabledLabel=false
                    v-model.trim="forgotPasswordObj.mobile"
                    :placeholder="$t(`field.phone`)"
                    :rules="validator.mobileNumberRules(defaultLanguage)">
                </app-form-field>
             </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters>
        <v-col cols=4 class="pr-1">
          <button-primary
              :title="this.$store.state.member.resetForgotPasswordSent.countDownTimer > 0 ? formatSeconds(this.$store.state.member.resetForgotPasswordSent.countDownTimer) : $t(`button.requestOtp`)"
              :action="this.requestSmsVerificationCode" 
              :disabled="this.$store.state.member.resetForgotPasswordSent.countDownTimer > 0"
              :customClass="'width-auto font-weight-bold black--text'">
          </button-primary>
        </v-col>
        <v-col cols=8>
          <v-form ref="otpForm">
            <app-form-field
              :enabledLabel=false
              v-model.trim="forgotPasswordObj.smsToken"
              :placeholder="$t(`field.otpNo`)"
              :rules="validator.requiredRules(4,6)"
              :isOptional="false">
            </app-form-field>
          </v-form>
        </v-col>
      </v-row>

      <p class="text-right subtitle-2">
          <a class="link-underscore" @click="openLoginDialog">{{ $t(`label.login`) }}</a>
      </p>
      <v-row no-gutters>
        <v-col cols="12">
          <button-primary
              :title="$t('button.reset')"
              :action="this.forgotPassword" 
              :customClass="'full-width font-weight-bold black--text'">
          </button-primary>
        </v-col>
        <v-col cols="12" class="text-center">
            <label v-show="smsRequestError" class="red--text subtitle-2">{{smsRequestError}}</label>
        </v-col>
      </v-row>
      <p class="mt-5 text-left subtitle-2">
          {{ $t(`message.dontHaveAccount`) }}
          <a class="link-underscore" @click="openRegister">{{ $t(`label.signUp`) }}</a>
      </p>
      </v-col>
  </v-row>
</template>

<script>
  import stringFormat from 'string-format'
  import {
    formValidator,
    uiHelper,
    errorCodeHelper,
    locale
  } from '@/util'
  import {
    MEMBER_SEND_OTP,
    MEMBER_VERIFY_OTP,
    MEMBER_RESET_VERIFY_OTP,
    MEMBER_RESET_SEND_OTP,
    MEMBER_GET_SMS_REQUIREMENT,
    MEMBER_REQUEST_FORGOT_PASSWORD,
    MEMBER_VERIFY_FORGOT_PASSWORD,
    MEMBER_RESET_VERIFY_FORGOT_PASSWORD, MEMBER_RESET_REQUEST_FORGOT_PASSWORD
  } from '@/store/member.module'
  import {
    SHARED
  } from '@/constants/constants'
  import { ROUTE_NAME } from '@/constants/route.constants'
  import {SHARED_LOADING} from '@/store/shared.module'
  import Captcha from "@/components/Captcha.vue";
  export default {
    name: 'appForgotPassword',
    components: {Captcha},
    props: {
      openRegisterDialog: {
        type: Function,
        required: true,
        default: () => {}
      },
      openLoginDialog: {
        type: Function,
        required: true,
        default: () => {}
      }
    },
    mounted() {
      this.$store.dispatch(`${MEMBER_RESET_SEND_OTP}`)
      //this.$refs.forgotPasswordForm.reset()
      //this.forgotPasswordObj.mobileNumberPrefix = mobileNumberPrefixList[0].value;
    },
    computed: {
      // smsVerificationSent() {
      //   return this.$store.state.member.otpSent.success
      // },
      smsRequestError() {
        if(this.$store.state.member.otpSent.message){
          try{
            return this.$store.state.member.otpSent.message.data[0]
          }catch(err){
            return ""
          }
        }
        return ""
      },
      otpVerificationSent() {
        return this.$store.state.member.resetForgotPasswordSent.complete;
      },
      smsRequestAvailable() {
        return this.$store.state.member.otpSent.countDownTimer <= 0
      },
      smsCountdownTimer() {
        return this.$store.state.member.otpSent.countDownTimer
      },
      smsVerificationResult() {
        return this.$store.state.member.otpVerified.completed
      },
      verifyForgotPasswordSent() {
        return this.$store.state.member.verifyForgotPasswordSent.complete;
      }
    },
    watch: {
      otpVerificationSent() {
        let response = this.$store.state.member.resetForgotPasswordSent;
        if (response.complete) {
          if (!response.success) {
            if(response.code == -1) {
              this.openAlert(response.success, locale.getMessage("fieldErrorMessage.invalidCaptchaCode"));
            } else if(response.code === "3.691") {
              this.openAlert(response.success, response.error);
            } else {
              this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
            }
            this.$store.dispatch(`${MEMBER_RESET_REQUEST_FORGOT_PASSWORD}`);
          }
        }
      },
      verifyForgotPasswordSent() {
        let response = this.$store.state.member.verifyForgotPasswordSent;
        if (response.complete) {
          if (response.success) {
            this.openAlert(response.success, "success");
            this.$router.push({
              name: ROUTE_NAME.RESET_PASSWORD,
              query: {
                memberCode: response.membercode,
                contact: response.contact
              }
            });
          } else {
            if(response.code == -1) {
              this.openAlert(response.success, locale.getMessage("fieldErrorMessage.invalidCaptchaCode"));
            } else if(response.code == '3.691') {
              this.openAlert(response.success, response.error);
            } else {
              this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
            }
          }
          this.$store.dispatch(`${MEMBER_RESET_VERIFY_FORGOT_PASSWORD}`);
          this.$store.dispatch(`${SHARED_LOADING}`);
        }
      },
    },
      // smsVerificationResult() {
      //   let response = this.$store.state.member.otpVerified
      //   if(response.completed){
      //     if(response.success) {
      //       this.openAlert(response.success, "success");
      //
      //       this.$router.push({
      //           name: ROUTE_NAME.RESET_PASSWORD,
      //           query: {
      //             memberCode: this.forgotPasswordObj.memberCode,
      //             key: response.key
      //           }
      //       })
      //     }
      //     else {
      //       this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code) );
      //     }
      //     this.$store.dispatch(`${MEMBER_RESET_VERIFY_OTP}`)
      //     this.$store.dispatch(`${SHARED_LOADING}`)
      //   }
      //
      // }
    data: () => ({
      defaultLanguage:SHARED.DEFAULT_LANGUAGE,
      validator: formValidator,
      stringFormat: stringFormat,
      retainSignIn: false,
      forgotPasswordObj: {
        memberCode: '',
        mobile: '',
        smsToken: '',
        prefix: '+880'
      },
      mobileNumberPrefixList: [
        // {
        //   text: "+91",
        //   value: "+91"
        // },
        {
          text: "+880",
          value: "+880"
        }
      ],
      currency: "", 
      alertShow: false,
      alertSuccess: false,
      alertMessage: '',
      captcha: "",
      captchaErrorMsg: undefined
    }),
    methods: {
      getMobileNumberDisplayText(data) {
        return data.text
      },
      requestSmsVerificationCode() {
        this.$store.state.member.otpSent.success = false
        if (this.captcha != null && this.$refs.forgotPasswordForm.validate()) {
          let data = {
            mobileNumber: this.forgotPasswordObj.mobile,
            prefix: this.forgotPasswordObj.prefix,
            captchaId: this.$refs.captchaCode.$refs.code.id,
            captchaCode: this.captcha,
          }
          this.$store.dispatch(`${MEMBER_REQUEST_FORGOT_PASSWORD}`, {
            data
          })
        }
      },
      forgotPassword() {
        if (this.captcha != null && this.$refs.otpForm.validate()) {
          if (this.$refs.forgotPasswordForm.validate()) {
            let data = {
              mobileNumber: this.forgotPasswordObj.mobile,
              smsToken: this.forgotPasswordObj.smsToken,
              captchaId: this.$refs.captchaCode.$refs.code.id,
              captchaCode: this.captcha,
            }
            this.$store.dispatch(`${MEMBER_VERIFY_FORGOT_PASSWORD}`, {
              data
            })
          }
        }
      },
      openRegister() {
        this.$refs.forgotPasswordForm.reset()
        this.openRegisterDialog()
      },
      openAlert(success, msg) {
        this.alertSuccess = success
        this.alertMessage = msg
        this.alertShow = true
      },
      formatSeconds(secs) {
        const pad = (n) => n < 10 ? `0${n}` : n

        const h = Math.floor(secs / 3600)
        const m = Math.floor(secs / 60) - (h * 60)
        const s = Math.floor(secs - h * 3600 - m * 60)

        return `${pad(m)}:${pad(s)}`
      },
    }
  }
</script>

<style lang="scss">
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
  .forgot_password_panel {
      color: #000000;
      background-color: #ffffff;
      padding: 12px 16px !important;

      .width-auto  {
        width: 100%;
        min-width: unset;
      }

      .link-underscore:hover {
        text-decoration: underline;
        color: var(--v-primary-base);
      }

      input::placeholder { 
        color: var(--v-description-base) !important;
        opacity: 1; 
      }

      .otp-button {
        background-color: var(--v-primary-base);
        border-radius: 4px;
      }
  }
</style>
